import React from 'react';

function CancelPage() {
  return (
    <div className="flex min-h-screen bg-gray-50 overflow-y-auto">
      <div className="flex-grow p-6 mt-16 md:mt-0 flex justify-center items-center">
        <section className="pt-12 pb-12 bg-blueGray-50 overflow-hidden w-full">
          <div className="containersm:px-8 md:px-12 mx-auto">
            <h2 className="mb-12 text-4xl md:text-4xl font-bold text-center tracking-px-n leading-tight w-full">
              Pago cancelado
            </h2>
            <p className="text-center">Tu pago ha sido cancelado. No se ha realizado ningún cargo.</p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default CancelPage;
