import React, { useState, useContext } from 'react';
import supabase from './services/supabaseClient';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import logo from '../assets/logo.png';  // Importa el logo desde la ruta especificada

const generatePassword = () => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
  let password = "";
  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const redirectUser = (estado, navigate) => {
  switch (estado) {
    case 'Cliente Primeros Pasos':
      navigate('/primerospasos');
      break;
    case 'Cliente Subir Doc':
      navigate('/subirdocumentacion');
      break;
    case 'Cliente Firmar Documentación':
      navigate('/firmarautorizacion');
      break;
    case 'Cliente Como Va mi Caso':
      navigate('/comovamicasso');
      break;
    case 'Cliente Caso Resuelto':
      navigate('/casoresuelto');
      break;
    default:
      navigate('/simulacioncbp');
      break;
  }
};

function Signup() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const password = generatePassword();
    const { error } = await supabase.auth.signUp({
      email: email,
      password: password
    });

    if (error) {
      toast.error(error.message);
    } else {
      toast.success("Registro exitoso!");

      const { data: loginData, error: loginError } = await supabase.auth.signInWithPassword({
        email: email,
        password: password
      });

      if (loginError) {
        toast.error(loginError.message);
      } else {
        toast.success("Inicio de sesión exitoso!");
        setUser(loginData.user);

        const redirectToUrl = `${window.location.origin}/passwordrecovery`;
        const { error: resetError } = await supabase.auth.resetPasswordForEmail(email, {
          redirectTo: redirectToUrl
        });

        if (resetError) {
          toast.error(resetError.message);
        } else {
          toast.success("Correo de restablecimiento de contraseña enviado!");
        }

        const { data, error: stateError } = await supabase
          .from('customer_journey')
          .select('estado')
          .eq('user_id', loginData.user.id)
          .order('timestamp', { ascending: false })
          .limit(1)
          .single();

        if (stateError) {
          console.error(stateError);
          navigate('/simulacioncbp'); // Default to the desired route if state fetch fails
        } else {
          redirectUser(data?.estado, navigate);
        }
      }
    }
  };

  return (
    <section className="py-16 xl:pb-56 bg-white overflow-hidden">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap justify-between items-center mb-8">
          <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
            <img src={logo} alt="Logo" className="mb-8 w-40" />
            <h1 className="text-2xl md:text-4xl font-bold font-heading tracking-px-n leading-tight mb-4">Comprueba ahora si cumples los requisitos del código de buenas prácticas</h1>
            <h2 className="mb-12 font-medium text-lg text-gray-600 leading-normal">Pon tu correo y haz click en comenzar simulador, en 5 minutos sabras si peudes reducir la cuota de tu hipoteca</h2>
            <form onSubmit={handleSubmit}>
              <label className="block mb-5">
                <input
                  className="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                  type="email" placeholder="Correo Electrónico"
                  name="email" onChange={handleChange}
                />
              </label>
              <button type="submit" className="py-4 px-9 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200">Comenzar simulador</button>
              <div className="mt-4">
                <a className="text-indigo-600 hover:text-indigo-700" href="/signin">Entra aquí si ya tienes una cuenta</a>
              </div>
            </form>
          </div>
          <div className="w-full lg:w-1/2 px-4">
            <div className="wistia_responsive_padding" style={{padding: '56.25% 0 0 0', position: 'relative'}}>
              <div className="wistia_responsive_wrapper" style={{height: '100%', left: 0, position: 'absolute', top: 0, width: '100%'}}>
                <iframe src="https://fast.wistia.net/embed/iframe/570e3y4tlz?seo=true&videoFoam=true" title="exportedVideo-26 de abril de 2024, 12-22-27" allow="autoplay; fullscreen" allowtransparency="true" frameBorder="0" scrolling="no" className="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe>
              </div>
            </div>
            <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
    </section>
  );
}

export default Signup;
