import React from 'react';

function CasoResuelto() {
    return (
        <div>
            <h1>CasoResuelto</h1>
            <p>Realiza el simulador del Código de Buenas Prácticas.</p>
        </div>
    );
}

export default CasoResuelto;
