// src/components/ComoVaMiCaso.js
import React, { useEffect, useState, useContext } from 'react';
import { FaGavel, FaEnvelope, FaInfoCircle, FaThumbsUp } from 'react-icons/fa';
import supabase from './services/supabaseClient';
import { UserContext } from './UserContext'; // Asegúrate de que tienes un UserContext configurado

const timelineElements = [
  { key: 5, title: "El abogado está revisando tu caso", icon: <FaGavel />, iconBg: 'bg-gray-300', inProgress: true },
  { key: 6, title: "El abogado ha enviado la solicitud al banco", icon: <FaEnvelope />, iconBg: 'bg-gray-300' },
  { key: 7, title: "Novedades de tu caso", icon: <FaInfoCircle />, iconBg: 'bg-gray-300' },
  { key: 8, title: "Caso Resuelto", icon: <FaThumbsUp />, iconBg: 'bg-gray-300' }, // Nuevo paso añadido
];

const ComoVaMiCaso = () => {
  const [caseUpdates, setCaseUpdates] = useState([]);
  const [requestedDocuments, setRequestedDocuments] = useState([]);
  const { user } = useContext(UserContext); // Obteniendo el objeto user del contexto

  // Agregar console.log para verificar el valor de user
  console.log('UserContext:', user);

  useEffect(() => {
    const cliente_id = user?.id; // Obteniendo cliente_id desde user.id

    if (cliente_id) { // Verifica que cliente_id no sea undefined
      const fetchNotesAndDocuments = async () => {
        // Fetch notas
        const { data: notas, error: notasError } = await supabase
          .from('notas')
          .select('id, usuario_nombre, usuario_apellidos, fecha, contenido')
          .eq('visibleparaelcliente', true)
          .eq('cliente_id', cliente_id)
          .order('fecha', { ascending: false });

        if (notasError) {
          console.error('Error fetching notes:', notasError);
          return;
        }

        // Fetch documentos
        const { data: documentos, error: documentosError } = await supabase
          .from('documentos')
          .select('nota_id, url_documento')
          .eq('tipo_documento', 'Documento Subido CBP')
          .eq('cliente_id', cliente_id);

        if (documentosError) {
          console.error('Error fetching documents:', documentosError);
          return;
        }

        // Crear URLs firmadas para los documentos con expiración de 90 días
        const documentosConUrlsFirmadas = await Promise.all(documentos.map(async (documento) => {
          const { data: signedUrlData, error: signedUrlError } = await supabase
            .storage
            .from('documentos')
            .createSignedUrl(documento.url_documento, 60 * 60 * 24 * 90); // Expiración de 90 días

          if (signedUrlError) {
            console.error('Error creating signed URL:', signedUrlError);
            return documento;
          }

          return {
            ...documento,
            signedUrl: signedUrlData.signedUrl
          };
        }));

        // Map documents to notes
        const notasConDocumentos = notas.map(nota => {
          const documento = documentosConUrlsFirmadas.find(doc => doc.nota_id === nota.id);
          return {
            ...nota,
            url_documento: documento ? documento.signedUrl : null
          };
        });

        const updates = notasConDocumentos.map((note, index) => ({
          key: index,
          name: `${note.usuario_nombre} ${note.usuario_apellidos}`,
          date: new Date(note.fecha).toLocaleString('es-ES', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }), // Formatear la fecha y hora
          description: note.contenido,
          url_documento: note.url_documento
        }));

        setCaseUpdates(updates);
      };

      const fetchRequestedDocuments = async () => {
        // Fetch documentos solicitados
        const { data: documentosSolicitados, error: documentosSolicitadosError } = await supabase
          .from('documentacion_solicitada')
          .select('id, tipo_documento, estado')
          .eq('cliente_id', cliente_id)
          .eq('estado', 'doc solicitada');

        if (documentosSolicitadosError) {
          console.error('Error fetching requested documents:', documentosSolicitadosError);
          return;
        }

        setRequestedDocuments(documentosSolicitados);
      };

      fetchNotesAndDocuments();
      fetchRequestedDocuments();
    }
  }, [user]);

  const handleFileUpload = async (event, documentId, tipoDocumento) => {
    const file = event.target.files[0];
    if (!file) return;

    const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
    const filePath = `documentos/${sanitizedFileName}`;

    // Actualizar el estado del documento a "pendiente" en la tabla documentacion_solicitada
    const { error: updateError } = await supabase
      .from('documentacion_solicitada')
      .update({ estado: 'pendiente' })
      .eq('id', documentId);

    if (updateError) {
      console.error('Error updating document status:', updateError);
      return;
    }

    // Subir el archivo al bucket de supabase
    const { data, error } = await supabase.storage
      .from('documentos')
      .upload(filePath, file);

    if (error) {
      console.error('Error uploading file:', error);
      return;
    }

    // Insertar un registro en la tabla documentos
    const { error: insertError } = await supabase
      .from('documentos')
      .insert({
        cliente_id: user.id,
        tipo_documento: tipoDocumento,
        url_documento: filePath,
        estado: 'pendiente'
      });

    if (insertError) {
      console.error('Error inserting document data:', insertError);
      return;
    }

    // Actualizar el estado del documento en la lista local
    setRequestedDocuments(prevDocuments =>
      prevDocuments.map(doc =>
        doc.id === documentId ? { ...doc, estado: 'pendiente' } : doc
      )
    );
  };

  const completedSteps = 0; // Actualiza el número de pasos completados
  const inProgressStep = 1; // Actualiza el paso en progreso
  const progressPercentage = (completedSteps / timelineElements.length) * 100;

  return (
    <div className="flex flex-col items-center pt-8 px-4 w-full h-screen"> {/* Ajustar la altura */}
      <h2 className="text-center text-2xl font-bold pt-8 mb-12 w-full">Enhorabuena, tu expediente está completo, sigue aquí el avance de tu caso</h2>
      <div className="flex flex-col lg:flex-row w-full space-y-8 lg:space-y-0 lg:space-x-8 flex-grow">
        <div className="relative w-full lg:w-1/3 border border-gray-300 rounded-lg p-6 flex-grow">
          <h3 className="text-xl font-bold mb-4 text-center">Estado de tu expediente</h3>
          <div className="absolute left-10 top-0 w-2 h-full bg-white border-4 border-purple-500">
            <div className="absolute left-0 top-0 w-full bg-purple-500" style={{ height: `${progressPercentage}%` }}></div>
          </div>
          {timelineElements.map((element, index) => (
            <div key={element.key} className="flex items-center mb-8 relative">
              <div className={`circle-icon p-2 w-10 h-10 ${index < completedSteps ? 'bg-purple-500' : index === inProgressStep - 1 ? 'bg-gray-300 border-4 border-purple-500' : 'bg-gray-300'} rounded-full flex items-center justify-center text-white text-xl z-10`}>
                {element.icon}
              </div>
              <div className={`ml-12 p-4 rounded shadow-lg w-full ${index < completedSteps ? 'border-4 border-purple-500 bg-white' : index === inProgressStep - 1 ? 'border-4 border-purple-500 bg-gray-100 text-gray-500' : 'border border-gray-300 bg-gray-100 text-gray-500'} relative`}>
                <h3 className={`text-lg font-semibold ${index >= completedSteps ? 'text-gray-500' : ''}`}>{element.title}</h3>
              </div>
            </div>
          ))}
        </div>
        <div className="relative w-full lg:w-1/3 border border-gray-300 rounded-lg p-6 flex-grow">
          <h3 className="text-xl font-bold mb-4">Novedades de tu caso</h3>
          <div className="relative w-full overflow-y-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
            {caseUpdates.map((update) => (
              <div key={update.key} className="flex flex-col mb-8 relative">
                <div className="bg-white p-4 rounded shadow-lg w-full border-2 border-purple-500 relative">
                  <div className="flex items-center justify-between mb-2">
                    <span className="font-semibold">{update.name}</span>
                    <span className="text-gray-500 text-sm font-bold">{update.date}</span> {/* Fecha y hora en negrita */}
                  </div>
                  <p>{update.description}</p>
                  {update.url_documento && (
                    <a href={update.url_documento} target="_blank" rel="noopener noreferrer" className="text-blue-500 mt-2">
                      Ver Documento
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="relative w-full lg:w-1/3 border border-gray-300 rounded-lg p-6 flex-grow">
          <h3 className="text-xl font-bold mb-4">Documentos Solicitados</h3>
          <p className="text-sm mb-4">Aquí te aparecerán los documentos que te solicitamos para completar el expediente:</p>
          <div className="relative w-full overflow-y-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
            {requestedDocuments.map((document, index) => (
              <div key={index} className="flex items-center mb-4 relative">
                <span className="font-semibold">{document.tipo_documento}</span>
                <label className="flex items-center text-white bg-gradient-to-r from-purple-500 to-blue-500 px-4 py-2 rounded-md hover:from-purple-600 hover:to-blue-600 cursor-pointer ml-4">
                  {document.estado === 'pendiente' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                    </svg>
                  )}
                  {document.estado === 'pendiente' ? 'Documento Subido' : 'Cargar Documento'}
                  <input type="file" className="hidden" onChange={(event) => handleFileUpload(event, document.id, document.tipo_documento)} />
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComoVaMiCaso;
