import React, { useState, useEffect } from 'react';
import supabase from './services/supabaseClient';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function PasswordRecovery() {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordRecoveryEvent, setPasswordRecoveryEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "PASSWORD_RECOVERY") {
        setPasswordRecoveryEvent({ session });
        if (session?.user?.email) {
          setEmail(session.user.email);
        }
      }
      setLoading(false);
    });

    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      }
    };
  }, [navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordRecoveryEvent) {
      const { session } = passwordRecoveryEvent;
      const { data, error } = await supabase.auth.updateUser({ password: newPassword });

      if (data) {
        toast.success("Contraseña actualizada con éxito!");
        setTimeout(() => {
          navigate('/simulacioncbp');
        }, 2000);
      } else if (error) {
        toast.error("Hubo un error al actualizar tu contraseña.");
        console.error("Error updating password:", error);
      }
    } else {
      toast.error("No hay evento de recuperación de contraseña activo.");
    }
  };

  if (loading) {
    return <div>Recuperación de contraseña en proceso...</div>;
  }

  if (!passwordRecoveryEvent) {
    return <div>No hay eventos de recuperación de contraseña activos.</div>;
  }

  return (
    <section className="py-16 xl:pb-56 bg-white overflow-hidden">
      <div className="container px-4 mx-auto">
        <div className="text-center max-w-md mx-auto">
          <a className="mb-36 inline-block" href="#">
            <img src="https://static.shuffle.dev/uploads/files/de/de8bbe28da6510aad1564efc805f24bab72d4918/cbp-12.svg" alt="Logo" />
          </a>
          <h2 className="mb-4 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight">Recupera tu contraseña</h2>
          <p className="mb-12 font-medium text-lg text-gray-600 leading-normal">Ingresa tu nueva contraseña para restablecerla.</p>
          <form onSubmit={handleSubmit}>
            <label className="block mb-5">
              <input
                className="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                type="email" placeholder="Correo Electrónico"
                name="email" value={email} onChange={handleEmailChange}
                required
              />
            </label>
            <label className="block mb-5">
              <input
                className="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                type="password" placeholder="Nueva Contraseña"
                name="newPassword" onChange={handlePasswordChange}
                required
              />
            </label>
            <button type="submit" className="mb-8 py-4 px-9 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200">Actualizar Contraseña</button>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
    </section>
  );
}

export default PasswordRecovery;
