import React, { useState, useEffect, useContext } from 'react';
import supabase from './services/supabaseClient';
import { UserContext } from './UserContext';
import bankOptions from '../data/bankOptions';
import Select from 'react-select';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { setAppElement } from 'react-modal';

setAppElement('#root');

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const { user } = useContext(UserContext);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('currentStep', step);
  }, [step]);

  useEffect(() => {
    const storedFormData = localStorage.getItem('formData');
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }

    if (user && user.id) {
      console.log('User ID:', user.id);
      fetchUserData(user.id);
    }
  }, [user]);

  const fetchUserData = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('simulacion_resultados')
        .select(`
          nombre,
          apellidos,
          telefono,
          email,
          bancoHipoteca,
          pagoMensualHipoteca,
          cuotaJunio2022,
          cuotaHace4Anos,
          ingresosBrutosAnuales,
          ingresosNetosAnuales,
          ingresosHace4Anos,
          cantidadPorPagar,
          plazoOriginalHipoteca,
          interesAnual,
          domicilio
        `)
        .eq('id', userId)
        .maybeSingle();

      if (error) {
        console.error('Error fetching data:', error);
      } else if (data) {
        const storedFormData = localStorage.getItem('formData');
        const parsedStoredFormData = storedFormData ? JSON.parse(storedFormData) : {};

        setFormData((prevData) => ({
          ...prevData,
          ...data,
          ...parsedStoredFormData,
          titular1_nombre: data.nombre,
          titular1_apellidos: data.apellidos,
        }));
      } else {
        console.log('No rows found for user ID:', userId);
      }
    } catch (error) {
      console.error('Unexpected error fetching data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      localStorage.setItem('formData', JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: selectedOption ? selectedOption.value : '' };
      localStorage.setItem('formData', JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const validateStep = (currentStep) => {
    if (currentStep === 1) {
      return formData.numeroTitulares && formData.miembrosUnidadFamiliar;
    } else if (currentStep === 2) {
      return formData.nombre && formData.apellidos && formData.telefono && formData.email && formData.bancoHipoteca && formData.domicilio;
    } else if (currentStep === 3) {
      const numeroTitulares = parseInt(formData.numeroTitulares, 10) || 0;
      const miembrosUnidadFamiliar = parseInt(formData.miembrosUnidadFamiliar, 10) || 0;

      for (let i = 1; i <= numeroTitulares; i++) {
        if (!formData[`titular${i}_nombre`] || !formData[`titular${i}_apellidos`] || !formData[`titular${i}_dni`] || !formData[`titular${i}_ingresosBrutosAnuales`] || !formData[`titular${i}_ingresosNetosAnuales`]) {
          return false;
        }
      }

      for (let i = 1; i <= (miembrosUnidadFamiliar - numeroTitulares); i++) {
        if (!formData[`miembro${i}_nombre`] || !formData[`miembro${i}_apellidos`] || !formData[`miembro${i}_dni`]) {
          return false;
        }
      }

      return true;
    }
    return true;
  };

  const saveDataToSupabase = async () => {
    const numeroTitulares = parseInt(formData.numeroTitulares, 10) || 0;
    const miembrosUnidadFamiliar = parseInt(formData.miembrosUnidadFamiliar, 10) || 0;

    try {
      const { data: existingCliente, error: fetchClienteError } = await supabase
        .from('clientes')
        .select('id')
        .eq('id', user.id)
        .single();

      if (fetchClienteError && fetchClienteError.code !== 'PGRST116') {
        throw fetchClienteError;
      }

      if (existingCliente) {
        const { error: clienteError } = await supabase
          .from('clientes')
          .update({
            nombre: formData.titular1_nombre,
            apellidos: formData.titular1_apellidos,
            telefono: formData.telefono,
            email: formData.email,
            bancoHipoteca: formData.bancoHipoteca,
            pagoMensualHipoteca: formData.pagoMensualHipoteca,
            cuotaJunio2022: formData.cuotaJunio2022,
            cuotaHace4Anos: formData.cuotaHace4Anos,
            ingresosBrutosAnuales: formData.ingresosBrutosAnuales,
            ingresosNetosAnuales: formData.ingresosNetosAnuales,
            ingresosHace4Anos: formData.ingresosHace4Anos,
            cantidadPorPagar: formData.cantidadPorPagar,
            plazoOriginalHipoteca: formData.plazoOriginalHipoteca,
            interesAnual: formData.interesAnual,
            numeroTitulares: formData.numeroTitulares,
            miembrosUnidadFamiliar: formData.miembrosUnidadFamiliar,
            fechaVencimiento: formData.fechaVencimiento,
            domicilio: formData.domicilio
          })
          .eq('id', existingCliente.id);

        if (clienteError) {
          throw clienteError;
        }
      } else {
        const { error: clienteError } = await supabase
          .from('clientes')
          .insert([
            {
              id: user.id,
              nombre: formData.titular1_nombre,
              apellidos: formData.titular1_apellidos,
              telefono: formData.telefono,
              email: formData.email,
              bancoHipoteca: formData.bancoHipoteca,
              pagoMensualHipoteca: formData.pagoMensualHipoteca,
              cuotaJunio2022: formData.cuotaJunio2022,
              cuotaHace4Anos: formData.cuotaHace4Anos,
              ingresosBrutosAnuales: formData.ingresosBrutosAnuales,
              ingresosNetosAnuales: formData.ingresosNetosAnuales,
              ingresosHace4Anos: formData.ingresosHace4Anos,
              cantidadPorPagar: formData.cantidadPorPagar,
              plazoOriginalHipoteca: formData.plazoOriginalHipoteca,
              interesAnual: formData.interesAnual,
              numeroTitulares: formData.numeroTitulares,
              miembrosUnidadFamiliar: formData.miembrosUnidadFamiliar,
              fechaVencimiento: formData.fechaVencimiento,
              domicilio: formData.domicilio
            }
          ]);

        if (clienteError) {
          throw clienteError;
        }
      }

      // Insert or update the authenticated user as titular1 in the 'familiares' table
      const { data: existingTitular1, error: fetchTitular1Error } = await supabase
        .from('familiares')
        .select('id')
        .eq('cliente_id', user.id)
        .eq('dni', formData.titular1_dni)
        .single();

      if (fetchTitular1Error && fetchTitular1Error.code !== 'PGRST116') {
        throw fetchTitular1Error;
      }

      if (existingTitular1) {
        const { error: titular1Error } = await supabase
          .from('familiares')
          .update({
            nombre: formData.titular1_nombre,
            apellidos: formData.titular1_apellidos,
            tipoIngreso: formData.titular1_tipoIngreso,
            ingresosBrutosAnuales: formData.titular1_ingresosBrutosAnuales,
            ingresosNetosAnuales: formData.titular1_ingresosNetosAnuales,
            relacion: 'titular1',
            domicilio: formData.domicilio // Add domicilio update here
          })
          .eq('id', existingTitular1.id);

        if (titular1Error) {
          throw titular1Error;
        }
      } else {
        const { error: titular1Error } = await supabase
          .from('familiares')
          .insert([
            {
              cliente_id: user.id,
              nombre: formData.titular1_nombre,
              apellidos: formData.titular1_apellidos,
              dni: formData.titular1_dni,
              tipoIngreso: formData.titular1_tipoIngreso,
              ingresosBrutosAnuales: formData.titular1_ingresosBrutosAnuales,
              ingresosNetosAnuales: formData.titular1_ingresosNetosAnuales,
              relacion: 'titular1',
              domicilio: formData.domicilio // Add domicilio insert here
            }
          ]);

        if (titular1Error) {
          throw titular1Error;
        }
      }

      // Save other titulares
      for (let i = 2; i <= numeroTitulares; i++) {
        const { data: existingTitular, error: fetchTitularError } = await supabase
          .from('familiares')
          .select('id')
          .eq('cliente_id', user.id)
          .eq('dni', formData[`titular${i}_dni`])
          .single();

        if (fetchTitularError && fetchTitularError.code !== 'PGRST116') {
          throw fetchTitularError;
        }

        if (existingTitular) {
          const { error: titularError } = await supabase
            .from('familiares')
            .update({
              nombre: formData[`titular${i}_nombre`],
              apellidos: formData[`titular${i}_apellidos`],
              tipoIngreso: formData[`titular${i}_tipoIngreso`],
              ingresosBrutosAnuales: formData[`titular${i}_ingresosBrutosAnuales`],
              ingresosNetosAnuales: formData[`titular${i}_ingresosNetosAnuales`],
              relacion: 'titular',
              domicilio: formData.domicilio // Add domicilio update here
            })
            .eq('id', existingTitular.id);

          if (titularError) {
            throw titularError;
          }
        } else {
          const { error: titularError } = await supabase
            .from('familiares')
            .insert([
              {
                cliente_id: user.id,
                nombre: formData[`titular${i}_nombre`],
                apellidos: formData[`titular${i}_apellidos`],
                dni: formData[`titular${i}_dni`],
                tipoIngreso: formData[`titular${i}_tipoIngreso`],
                ingresosBrutosAnuales: formData[`titular${i}_ingresosBrutosAnuales`],
                ingresosNetosAnuales: formData[`titular${i}_ingresosNetosAnuales`],
                relacion: 'titular',
                domicilio: formData.domicilio // Add domicilio insert here
              }
            ]);

          if (titularError) {
            throw titularError;
          }
        }
      }

      // Save other family members
      for (let i = 1; i <= (miembrosUnidadFamiliar - numeroTitulares); i++) {
        const { data: existingMiembro, error: fetchMiembroError } = await supabase
          .from('familiares')
          .select('id')
          .eq('cliente_id', user.id)
          .eq('dni', formData[`miembro${i}_dni`])
          .single();

        if (fetchMiembroError && fetchMiembroError.code !== 'PGRST116') {
          throw fetchMiembroError;
        }

        if (existingMiembro) {
          const { error: miembroError } = await supabase
            .from('familiares')
            .update({
              nombre: formData[`miembro${i}_nombre`],
              apellidos: formData[`miembro${i}_apellidos`],
              tipoIngreso: formData[`miembro${i}_tipoIngreso`],
              ingresosBrutosAnuales: formData[`miembro${i}_ingresosBrutosAnuales`],
              ingresosNetosAnuales: formData[`miembro${i}_ingresosNetosAnuales`],
              relacion: formData[`miembro${i}_relacion`],
              domicilio: formData.domicilio // Add domicilio update here
            })
            .eq('id', existingMiembro.id);

          if (miembroError) {
            throw miembroError;
          }
        } else {
          const { error: miembroError } = await supabase
            .from('familiares')
            .insert([
              {
                cliente_id: user.id,
                nombre: formData[`miembro${i}_nombre`],
                apellidos: formData[`miembro${i}_apellidos`],
                dni: formData[`miembro${i}_dni`],
                tipoIngreso: formData[`miembro${i}_tipoIngreso`],
                ingresosBrutosAnuales: formData[`miembro${i}_ingresosBrutosAnuales`],
                ingresosNetosAnuales: formData[`miembro${i}_ingresosNetosAnuales`],
                relacion: formData[`miembro${i}_relacion`],
                domicilio: formData.domicilio // Add domicilio insert here
              }
            ]);

          if (miembroError) {
            throw miembroError;
          }
        }
      }

      const { error: journeyError } = await supabase
        .from('customer_journey')
        .upsert([
          {
            user_id: user.id,
            estado: 'Cliente Subir Doc',
            timestamp: new Date(),
            updated_at: new Date()
          }
        ], { onConflict: 'user_id' });

      if (journeyError) {
        throw journeyError;
      }

      setSuccessModalIsOpen(true);
      setTimeout(() => {
        setSuccessModalIsOpen(false);
        navigate('/subirdocumentacion');
      }, 3000);

    } catch (error) {
      console.error('Error saving data to Supabase:', error);
      setAlertMessage('Error al guardar los datos. Por favor, inténtelo de nuevo.');
      setAlertModalIsOpen(true);
    }
  };

  const handleFinalizarPrimerosPasos = async () => {
    setIsSubmitting(true);
    await saveDataToSupabase();
    setIsSubmitting(false);
  };

  const nextStep = async () => {
    if (validateStep(step)) {
      setStep((prevStep) => prevStep + 1);
    } else {
      setAlertMessage('Por favor, complete todos los campos requeridos para avanzar.');
      setAlertModalIsOpen(true);
    }
  };

  const closeAlertModal = () => {
    setAlertModalIsOpen(false);
  };

  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };

  const steps = [
    'Nr titulares y unidad familiar',
    'Revisa datos simulador',
    'Datos titulares y conyuge',
  ];

  const bankOptionsFormatted = bankOptions.map(bank => ({ value: bank, label: bank }));

  const incomeOptions = [
    { value: 'Nomina', label: 'Nomina' },
    { value: 'Pension', label: 'Pension' },
    { value: 'Ayuda', label: 'Ayuda' },
    { value: 'Prestacion Desempleo', label: 'Prestacion Desempleo' },
    { value: 'Facturas', label: 'Facturas' },
    { value: 'No tiene ingresos', label: 'No tiene ingresos' },
  ];

  const relationOptions = [
    { value: 'conyuge', label: 'Cónyuge' },
    { value: 'pareja de hecho', label: 'Pareja de hecho' },
    { value: 'hijo/hija', label: 'Hijo/Hija' },
  ];

  const renderTitulares = () => {
    const titulares = [];
    const numeroTitulares = parseInt(formData.numeroTitulares, 10) || 0;
    const miembrosUnidadFamiliar = parseInt(formData.miembrosUnidadFamiliar, 10) || 0;

    for (let i = 1; i <= numeroTitulares; i++) {
      titulares.push(
        <div key={`titular-${i}`} className="border-b-2 border-gray-300 py-4">
          <h3 className="text-lg font-semibold mb-2">Titular {i}</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {[
              { label: 'Nombre', name: `titular${i}_nombre` },
              { label: 'Apellidos', name: `titular${i}_apellidos` },
              { label: 'DNI', name: `titular${i}_dni` },
              { label: 'Ingresos brutos anuales actuales', name: `titular${i}_ingresosBrutosAnuales` },
              { label: 'Ingresos netos anuales actuales', name: `titular${i}_ingresosNetosAnuales` },
            ].map((field, index) => (
              <div key={index}>
                <label className="block text-gray-700 mb-2">{field.label}</label>
                <input
                  type="text"
                  name={field.name}
                  value={formData[field.name] || ''}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded bg-gray-100"
                />
              </div>
            ))}
            <div>
              <label className="block text-gray-700 mb-2">Tipo ingreso</label>
              <Select
                name={`titular${i}_tipoIngreso`}
                value={incomeOptions.find(option => option.value === formData[`titular${i}_tipoIngreso`]) || null}
                onChange={(selectedOption) => handleSelectChange(selectedOption, `titular${i}_tipoIngreso`)}
                options={incomeOptions}
                className="w-full px-3 py-2 border rounded bg-gray-100"
              />
            </div>
          </div>
        </div>
      );
    }

    if (miembrosUnidadFamiliar > numeroTitulares) {
      for (let i = 1; i <= (miembrosUnidadFamiliar - numeroTitulares); i++) {
        titulares.push(
          <div key={`miembro-${i}`} className="border-b-2 border-gray-300 py-4">
            <h3 className="text-lg font-semibold mb-2">Miembro unidad familiar {i}</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {[
                { label: 'Nombre', name: `miembro${i}_nombre` },
                { label: 'Apellidos', name: `miembro${i}_apellidos` },
                { label: 'DNI', name: `miembro${i}_dni` },
                { label: 'Ingresos brutos anuales actuales', name: `miembro${i}_ingresosBrutosAnuales` },
                { label: 'Ingresos netos anuales actuales', name: `miembro${i}_ingresosNetosAnuales` },
              ].map((field, index) => (
                <div key={index}>
                  <label className="block text-gray-700 mb-2">{field.label}</label>
                  <input
                    type="text"
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded bg-gray-100"
                  />
                </div>
              ))}
              <div>
                <label className="block text-gray-700 mb-2">Tipo ingreso</label>
                <Select
                  name={`miembro${i}_tipoIngreso`}
                  value={incomeOptions.find(option => option.value === formData[`miembro${i}_tipoIngreso`]) || null}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, `miembro${i}_tipoIngreso`)}
                  options={incomeOptions}
                  className="w-full px-3 py-2 border rounded bg-gray-100"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Indica que es para ti</label>
                <Select
                  name={`miembro${i}_relacion`}
                  value={relationOptions.find(option => option.value === formData[`miembro${i}_relacion`]) || null}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, `miembro${i}_relacion`)}
                  options={relationOptions}
                  className="w-full px-3 py-2 border rounded bg-gray-100"
                />
              </div>
            </div>
          </div>
        );
      }
    }

    return titulares;
  };

  return (
    <div className="flex flex-col items-center mt-12 px-4 sm:px-0">
      <div className="w-full max-w-4xl bg-white p-6 shadow-xl rounded-lg">
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-2 sm:space-y-0">
            {steps.map((title, index) => (
              <div
                key={index}
                className={`flex-1 text-center py-2 px-4 border-b-2 cursor-pointer ${step === index + 1 ? 'font-bold text-purple-600 border-purple-600' : 'text-gray-400 border-transparent hover:text-purple-600 hover:border-purple-600'}`}
                onClick={() => setStep(index + 1)}
              >
                {title}
              </div>
            ))}
          </div>
        </div>

        <div>
          {step === 1 && (
            <>
              <h2 className="text-xl font-semibold mb-4">¿Cuantos titulares hay en la hipoteca incluyendote a ti?
                <br />(Ojo, solo titulares, no avalistas o fiadores)</h2>
              <form className="grid grid-cols-1 lg:grid-cols gap-6">
                <div>
                  <label className="block text-gray-700 mb-2">Número de titulares</label>
                  <input
                    type="number"
                    name="numeroTitulares"
                    value={formData.numeroTitulares || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded bg-gray-100"
                  />
                </div>
                <div>
                  <h2 className="text-xl font-semibold mb-4">¿Cuantas personas incluyendote a ti estan empadronados en la vivienda?
                    <br />(Empadronados y que tengan ingresos)</h2>
                  <label className="block text-gray-700 mb-2">Miembros de la unidad familiar</label>
                  <input
                    type="number"
                    name="miembrosUnidadFamiliar"
                    value={formData.miembrosUnidadFamiliar || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded bg-gray-100"
                  />
                </div>
              </form>
            </>
          )}
          {step === 2 && (
            <>
              <h2 className="text-xl font-semibold mb-4">Revisa los datos que has rellenado en el simulador. Comprueba que no hay ningún error y modifica lo que consideres oportuno</h2>
              <form className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {[
                  { label: 'Nombre', name: 'nombre' },
                  { label: 'Apellidos', name: 'apellidos' },
                  { label: 'Telefono', name: 'telefono' },
                  { label: 'Email', name: 'email' },
                  { label: 'Importe cuota hipoteca actual', name: 'pagoMensualHipoteca' },
                  { label: 'Importe cuota hipoteca junio 2022', name: 'cuotaJunio2022' },
                  { label: 'Importe cuota hipoteca hace 4 años', name: 'cuotaHace4Anos' },
                  { label: 'Ingresos brutos anuales actuales', name: 'ingresosBrutosAnuales' },
                  { label: 'Ingresos netos anuales actuales', name: 'ingresosNetosAnuales' },
                  { label: 'Ingresos netos anuales hace 4 años', name: 'ingresosHace4Anos' },
                  { label: 'Importe pendiente total de la hipoteca', name: 'cantidadPorPagar' },
                  { label: 'Fecha de vencimiento de la hipoteca', name: 'fechaVencimiento', type: 'date' },
                  { label: 'Plazo original de la hipoteca', name: 'plazoOriginalHipoteca' },
                  { label: 'Interés anual', name: 'interesAnual' },
                  { label: 'Domicilio completo', name: 'domicilio' },
                ].map((field, index) => (
                  <div key={index}>
                    <label className="block text-gray-700 mb-2">{field.label}</label>
                    <input
                      type={field.type || 'text'}
                      name={field.name}
                      value={formData[field.name] || ''}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded bg-gray-100"
                    />
                  </div>
                ))}
                <div>
                  <label className="block text-gray-700 mb-2">¿Con qué banco tiene la hipoteca? Busca la hipoteca en la lista.</label>
                  <Select
                    name="bancoHipoteca"
                    value={bankOptionsFormatted.find(option => option.value === formData.bancoHipoteca) || null}
                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'bancoHipoteca')}
                    options={bankOptionsFormatted}
                    className="w-full px-3 py-2 border rounded bg-gray-100"
                  />
                </div>
              </form>
            </>
          )}
          {step === 3 && (
            <>
              <h2 className="text-xl font-semibold mb-4">Datos titulares y cónyuge</h2>
              {(!formData.numeroTitulares || !formData.miembrosUnidadFamiliar) ? (
                <p className="text-red-600">Por favor, complete los datos del primer paso antes de continuar.</p>
              ) : (
                <form className="grid grid-cols-1 gap-6">
                  {renderTitulares()}
                </form>
              )}
              <button
                onClick={handleFinalizarPrimerosPasos}
                disabled={isSubmitting}
                className={`w-full py-2 rounded transition duration-200 mt-4 ${isSubmitting
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-purple-600 text-white hover:bg-purple-700'
                  }`}
              >
                {isSubmitting ? 'Guardando...' : 'Finalizar Primeros pasos'}
              </button>
            </>
          )}
          {step !== 3 && (
            <button
              onClick={nextStep}
              disabled={isSubmitting}
              className={`w-full py-2 rounded transition duration-200 mt-4 ${isSubmitting
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-purple-600 text-white hover:bg-purple-700'
                }`}
            >
              {isSubmitting ? 'Guardando...' : 'Siguiente'}
            </button>
          )}
        </div>
      </div>
      <Modal
        isOpen={alertModalIsOpen}
        onRequestClose={closeAlertModal}
        contentLabel="Modal Alerta"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-[80vh] overflow-auto">
          <h2 className="text-2xl font-semibold mb-4">Información Incompleta</h2>
          <p>{alertMessage}</p>
          <button
            onClick={
              closeAlertModal}
            className="mt-4 bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 transition duration-200"
          >
            Cerrar
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={successModalIsOpen}
        onRequestClose={closeSuccessModal}
        contentLabel="Modal Éxito"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-[80vh] overflow-auto text-center">
          <h2 className="text-2xl font-semibold mb-4">¡Felicidades!</h2>
          <p>Su solicitud ha sido enviada con éxito.</p>
          <p>Serás redirigido a la página de subir documentación en breve...</p>
        </div>
      </Modal>
    </div>
  );
};

export default Onboarding;
