import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const StyledDropzone = ({ onDrop, progress, isUploaded, estado }) => {
  const onDropCallback = useCallback(onDrop, [onDrop]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles
  } = useDropzone({ 
    onDrop: onDropCallback, 
    disabled: isUploaded && (estado !== 'rechazado' && estado !== 'esperando clave pin') 
  });

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="flex flex-col items-center justify-center w-full h-64 p-4 border-2 border-dashed border-blue-400 rounded-lg cursor-pointer bg-blue-50"
      {...getRootProps()}>
      <input {...getInputProps()} />
      {isUploaded && estado !== 'rechazado' && estado !== 'esperando clave pin' ? (
        <div className="flex flex-col items-center">
          <FaCloudUploadAlt className="text-green-500 text-6xl mb-4" />
          <p className="text-green-600">Documento subido</p>
        </div>
      ) : progress !== null && progress < 100 ? (
        <div className="flex justify-center items-center w-32 h-32">
          <CircularProgressbar value={progress} text={`${progress}%`} styles={{ 
            path: { stroke: `#6b46c1` }, 
            text: { fill: '#6b46c1' }
          }} />
        </div>
      ) : (
        <>
          <FaCloudUploadAlt className="text-blue-500 text-6xl mb-4" />
          {isDragActive ? (
            <p className="text-blue-500">Suelta los archivos aquí ...</p>
          ) : (
            <p className="text-blue-500">Arrastra y suelta archivos aquí o <span className="font-bold text-blue-700">Elige archivo</span></p>
          )}
        </>
      )}
      <ul>{files}</ul>
    </div>
  );
};

export default StyledDropzone;
