import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from './services/supabaseClient';
import documentacionConfig from '../data/documentacionConfig.json';
import { FaFileAlt, FaFileInvoice, FaHome, FaUser } from 'react-icons/fa';
import { UserContext } from './UserContext';

const DocumentacionSubida = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [familiares, setFamiliares] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [activeTab, setActiveTab] = useState(null);

    const normalizedDocumentacionConfig = {
        ...documentacionConfig,
        documentacion_ingresos: Object.fromEntries(
            Object.entries(documentacionConfig.documentacion_ingresos).map(
                ([key, value]) => [key.toLowerCase(), value]
            )
        )
    };

    useEffect(() => {
        if (user && user.id) {
            const fetchFamiliares = async () => {
                const { data, error } = await supabase
                    .from('familiares')
                    .select('*')
                    .eq('cliente_id', user.id);

                if (error) {
                    console.error('Error fetching familiares:', error);
                } else {
                    const validData = data.map(familiar => ({
                        ...familiar,
                        relacion: familiar.relacion?.trim().toLowerCase() || 'otro',
                        tipoIngreso: familiar.tipoIngreso?.trim().toLowerCase() || 'desconocido'
                    }));

                    setFamiliares(validData);
                    if (validData.length > 0) {
                        setActiveTab(validData[0].id);
                    }
                }
                setLoading(false);
            };

            const fetchUploadedDocuments = async () => {
                if (user && user.id) {
                    const { data, error } = await supabase
                        .from('documentos')
                        .select('*')
                        .eq('cliente_id', user.id);

                    if (error) {
                        console.error('Error fetching uploaded documents:', error);
                    } else {
                        const uploadedFilesMap = {};
                        data.forEach(doc => {
                            if (!uploadedFilesMap[doc.familiar_id]) {
                                uploadedFilesMap[doc.familiar_id] = {};
                            }
                            uploadedFilesMap[doc.familiar_id][doc.tipo_documento] = {
                                url: doc.url_documento,
                                estado: doc.estado
                            };
                        });
                        setUploadedFiles(uploadedFilesMap);
                    }
                }
            };

            fetchFamiliares();
            fetchUploadedDocuments();
        }
    }, [user]);

    if (loading) {
        return <p>Loading...</p>;
    }

    const getIcon = (docName) => {
        switch (docName) {
            case 'Renta 2020':
            case 'Renta 2021':
            case 'Renta 2022':
            case 'Renta 2023':
            case 'Ultimas 3 nominas':
            case 'Ultimas 3 facturas':
            case 'Certificado de prestaciones':
            case 'Certificado Sepe':
                return <FaFileInvoice className="text-green-500" />;
            case 'Certificado de empadronamiento':
            case 'Certificado Catastral o Nota de localización':
                return <FaHome className="text-yellow-500" />;
            case 'Copia dni frontal':
            case 'Copia dni anverso':
                return <FaUser className="text-blue-500" />;
            case 'Libro Familia':
            case 'Declaración Responsable':
            case 'Autorización':
            case 'Escritura Hipoteca':
                return <FaFileAlt className="text-red-500" />;
            case 'Escrituras de compraventa de la vivienda':
                return <FaHome className="text-yellow-500" />;
            default:
                return <FaFileAlt className="text-blue-500" />;
        }
    };

    const getStatusClass = (estado) => {
        switch (estado) {
            case 'aprobado':
                return 'bg-green-100 text-green-600';
            case 'rechazado':
                return 'bg-red-100 text-red-600';
            case 'pendiente':
                return 'bg-yellow-100 text-yellow-600';
            case 'esperando clave pin':
                return 'bg-purple-100 text-purple-600';
            default:
                return 'bg-gray-100 text-gray-600';
        }
    };

    const renderDocumentacion = (persona) => {
        const relacion = persona.relacion ? persona.relacion.toLowerCase() : 'otro';
        const tipoIngreso = persona.tipoIngreso ? persona.tipoIngreso.toLowerCase() : 'desconocido';

        let documentacionHaciendaSeguridadSocial = [
            ...normalizedDocumentacionConfig.requisitos_generales.doc_hacienda_y_seguridad_social
        ];

        let documentacionPersonal = [
            ...normalizedDocumentacionConfig.requisitos_generales.documentacion_personal
        ];

        if (relacion === 'titular1') {
            documentacionHaciendaSeguridadSocial = [
                ...documentacionHaciendaSeguridadSocial,
                ...normalizedDocumentacionConfig.documentacion_relacion_titular1.doc_hacienda_y_seguridad_social
            ];

            documentacionPersonal = [
                ...documentacionPersonal,
                ...normalizedDocumentacionConfig.documentacion_relacion_titular1.documentacion_personal
            ];
        }

        if (tipoIngreso in normalizedDocumentacionConfig.documentacion_ingresos) {
            const docConfig = normalizedDocumentacionConfig.documentacion_ingresos[tipoIngreso];
            documentacionHaciendaSeguridadSocial.push(...docConfig.doc_hacienda_y_seguridad_social);
            documentacionPersonal.push(...docConfig.documentacion_personal);
        }

        return (
            <div className="space-y-4">
                <h4 className="font-semibold">Documentación Hacienda y Seguridad Social</h4>
                <ul className="space-y-2">
                    {documentacionHaciendaSeguridadSocial.map((doc, index) => (
                        <li key={index} className="flex justify-between items-center p-4 border rounded-md bg-gray-100">
                            <div className="flex items-center">
                                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-100 mr-2">
                                    {getIcon(doc)}
                                </div>
                                {doc || 'Documento sin nombre'}
                            </div>
                            <div className="flex items-center space-x-2">
                                {uploadedFiles[persona.id] && uploadedFiles[persona.id][doc] && (
                                    <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-semibold ${getStatusClass(uploadedFiles[persona.id][doc].estado)}`}>
                                        <span className="w-2 h-2 mr-2 rounded-full bg-current"></span>
                                        {uploadedFiles[persona.id][doc].estado}
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
                <h4 className="font-semibold mt-4">Documentación Personal</h4>
                <ul className="space-y-2">
                    {documentacionPersonal.map((doc, index) => (
                        <li key={index} className="flex justify-between items-center p-4 border rounded-md bg-gray-100">
                            <div className="flex items-center">
                                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-100 mr-2">
                                    {getIcon(doc)}
                                </div>
                                {doc || 'Documento sin nombre'}
                            </div>
                            <div className="flex items-center space-x-2">
                                {uploadedFiles[persona.id] && uploadedFiles[persona.id][doc] && (
                                    <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-semibold ${getStatusClass(uploadedFiles[persona.id][doc].estado)}`}>
                                        <span className="w-2 h-2 mr-2 rounded-full bg-current"></span>
                                        {uploadedFiles[persona.id][doc].estado}
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const handleFirmarAutorizacion = () => {
        navigate('/firmarautorizacion');
    };

    return (
        <div className="p-4 max-w-7xl mx-auto pt-20">
            <h1 className="text-2xl font-bold mb-4">Documentación Subida por los Titulares y Miembros de la Familia</h1>
            <div className="flex space-x-4 mb-6 border-b">
                {familiares.map((familiar) => (
                    <div
                        key={familiar.id}
                        className={`py-2 cursor-pointer ${activeTab === familiar.id ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                        onClick={() => setActiveTab(familiar.id)}
                    >
                        {familiar.nombre ? `${familiar.nombre} ${familiar.apellidos}` : 'Desconocido'}
                    </div>
                ))}
            </div>
            {familiares.map((familiar) =>
                activeTab === familiar.id ? (
                    <div key={familiar.id} className="p-4 border rounded-md">
                        <h2 className="text-xl font-semibold mb-4">
                            {familiar.nombre ? `${familiar.nombre} ${familiar.apellidos}` : 'Desconocido'}
                        </h2>
                        {renderDocumentacion(familiar)}
                    </div>
                ) : null
            )}
            <div className="mt-6">
                <button 
                    className="bg-purple-600 text-white py-2 px-4 rounded hover:bg-purple-700 transition duration-200"
                    onClick={handleFirmarAutorizacion}
                >
                    Ir a Firmar Autorización
                </button>
            </div>
        </div>
    );
};

export default DocumentacionSubida;
