import React, { useEffect } from 'react';
import stripeLogo from '../assets/stripe.svg';
import amexLogo from '../assets/amex.svg';
import mastercardLogo from '../assets/mastercard.svg';
import paypalLogo from '../assets/paypal.svg';
import visaLogo from '../assets/visa.svg';
import applePayLogo from '../assets/apple-pay.svg';

const PricingPage = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex min-h-screen bg-gray-100 overflow-y-auto">
      <div className="flex-grow p-6 mt-16 md:mt-0 flex justify-center items-center">
        <section id="honorarios" className="pt-24 pb-28 bg-blueGray-50 overflow-hidden w-full">
          <div className="container px-4 mx-auto">
          <h2 className="mb-24 text-4xl md:text-4xl font-bold text-center tracking-px-n leading-tight w-full">
 Carencia 2 años:
</h2>

            <div className="mb-24 md:max-w-6xl mx-auto">
              <div className="flex flex-wrap -m-4">
                <div className="w-full md:w-1/3 p-4">
                  <div className="flex flex-col px-9 pt-8 pb-9 h-full bg-white bg-opacity-90 border-blueGray-100 rounded-4xl"
                    style={{ backdropFilter: 'blur(46px)' }}>
                    <div>
                      <span className="mb-9 inline-block text-sm text-indigo-600 font-semibold uppercase tracking-px leading-snug">BASIC
                        </span>
                      <h3 className="mb-1 text-4xl text-gray-900 font-bold leading-none">99€ al mes</h3>
                      <p className="mb-6 text-sm text-gray-600 font-medium leading-relaxed">99€ al mes durante 36 meses</p>
                      <p className="mb-9 text-gray-600 font-medium leading-relaxed">Haz la simulación, consigue toda la
                        documentación en 5 minutos, genera la solicitud y uno de nuestros abogados se encargara de hacer la
                        revisión, el envío y el seguimiento con el banco. Coste total 3546€</p>
                    </div>
                    <stripe-buy-button buy-button-id="buy_btn_1PGfX62M8Z9g7a3hmkcjmLPN"
                      publishable-key="pk_live_51P9ZdS2M8Z9g7a3hXkCB2ctgJsd1ZLeK624H4tycIMDVlIIeNfvw1qJfIZ9PPamCSJWSFhnHVIuszGkW4179iacd00bk3lVJpX">
                    </stripe-buy-button>
                  </div>
                </div>
                <div className="w-full md:w-1/3 p-4">
                  <div className="relative flex flex-col px-9 pt-8 pb-9 h-full bg-white bg-opacity-90 border-blueGray-100 rounded-4xl shadow-9xl"
                    style={{ backdropFilter: 'blur(46px)' }}>
                    <img className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
                      src="flaro-assets/images/pricing/gradient3.svg" alt="" />
                    <div className="relative z-10">
                      <span className="mb-9 inline-block text-sm text-indigo-600 font-semibold uppercase tracking-px leading-snug">PREMIUM
                        </span>
                      <h3 className="mb-1 text-4xl text-gray-900 font-bold leading-none">999€</h3>
                      <p className="mb-6 text-sm text-gray-600 font-medium leading-relaxed">Pago Unico <br /> Ahorrate 2565€</p>
                      <p className="mb-9 text-gray-600 font-medium leading-relaxed">Haz la simulación, consigue toda la
                        documentación en 5 minutos, genera la solicitud y uno de nuestros abogados se encargara de hacer la
                        revisión, el envío y el seguimiento con el banco. Coste total 999€ en un único pago.</p>
                      <stripe-buy-button buy-button-id="buy_btn_1PGIam2M8Z9g7a3hXSu8fWt2"
                        publishable-key="pk_live_51P9ZdS2M8Z9g7a3hXkCB2ctgJsd1ZLeK624H4tycIMDVlIIeNfvw1qJfIZ9PPamCSJWSFhnHVIuszGkW4179iacd00bk3lVJpX">
                      </stripe-buy-button>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/3 p-4">
                  <div className="flex flex-col px-9 pt-8 pb-9 h-full bg-white bg-opacity-90 border-blueGray-100 rounded-4xl"
                    style={{ backdropFilter: 'blur(46px)' }}>
                    <div>
                      <span className="mb-9 inline-block text-sm text-indigo-600 font-semibold uppercase tracking-px leading-snug">PRO</span>
                      <h3 className="mb-1 text-4xl text-gray-900 font-bold leading-none">499€</h3>
                      <p className="mb-6 text-sm text-gray-600 font-medium leading-relaxed">Pago inicial de 499€ <br /> 99€ al mes
                        durante 10 meses </p>
                      <p className="mb-9 text-gray-600 font-medium leading-relaxed">Haz la simulación, consigue toda la
                        documentación en 5 minutos, genera la solicitud y uno de nuestros abogados se encargara de hacer la
                        revisión, el envío y el seguimiento con el banco. Coste total del servicio 1489€</p>
                    </div>
                    <stripe-buy-button buy-button-id="buy_btn_1PGIT92M8Z9g7a3hXYMMzU9E"
                      publishable-key="pk_live_51P9ZdS2M8Z9g7a3hXkCB2ctgJsd1ZLeK624H4tycIMDVlIIeNfvw1qJfIZ9PPamCSJWSFhnHVIuszGkW4179iacd00bk3lVJpX">
                    </stripe-buy-button>
                  </div>
                </div>
              </div>
            </div>
            <p className="mb-4 text-sm text-gray-500 text-center font-medium leading-relaxed">Servicio de pago seguro</p>
            <div className="flex flex-wrap justify-center">
              <div className="w-auto">
                <a href="#">
                  <img src={stripeLogo} alt="Stripe" />
                </a>
              </div>
              <div className="w-auto">
                <a href="#">
                  <img src={amexLogo} alt="Amex" />
                </a>
              </div>
              <div className="w-auto">
                <a href="#">
                  <img src={mastercardLogo} alt="Mastercard" />
                </a>
              </div>
              <div className="w-auto">
                <img src={paypalLogo} alt="Paypal" />
              </div>
              <div className="w-auto">
                <a href="#">
                  <img src={visaLogo} alt="Visa" />
                </a>
              </div>
              <div className="w-auto">
                <a href="#">
                  <img src={applePayLogo} alt="Apple Pay" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PricingPage;
