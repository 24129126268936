import React, { useState, useContext } from 'react';
import { UserCircleIcon, LogoutIcon } from '@heroicons/react/solid';
import { UserContext } from './UserContext';
import { useNavigate } from 'react-router-dom';
import supabase from './services/supabaseClient'; 

const UserIcon = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUser(null);
    navigate('/signin');
  };

  return (
    <div className="relative">
      <button onClick={() => setMenuOpen(!menuOpen)} className="flex items-center space-x-2 focus:outline-none">
        <UserCircleIcon className="h-8 w-8 text-gray-600" />
        <span className="hidden md:inline-block text-gray-600">{user.email}</span>
      </button>
      {menuOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg py-2 z-50">
          <button onClick={handleLogout} className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100">
            <LogoutIcon className="h-5 w-5 mr-2" />
            Cerrar sesión
          </button>
        </div>
      )}
    </div>
  );
};

export default UserIcon;
