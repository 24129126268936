// ResultadoCarencia.js

import React from 'react';
import {
  InformationCircleIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  ClipboardCheckIcon,
  CalculatorIcon
} from '@heroicons/react/solid';

const MetricaCircular = ({ titulo, valor, unidad, dashoffset }) => (
  <div className="flex flex-col items-center pr-4 mb-4 md:mb-0">
    <div className="border border-gray-300 p-4 rounded-md flex flex-col items-center">
      <span className="text-xl text-gray-700 mb-4 font-bold">{titulo}</span>
      <div className="relative w-44 h-44">
        <svg className="absolute top-0 left-0 w-full h-full">
          <circle 
            className="text-blue-200" 
            strokeWidth="4" 
            stroke="currentColor" 
            fill="transparent" 
            r="60" 
            cx="88" 
            cy="88" 
          />
          <circle 
            className="text-purple-600" 
            strokeWidth="4" 
            strokeDasharray="377" 
            strokeDashoffset={dashoffset} 
            strokeLinecap="round" 
            stroke="currentColor" 
            fill="transparent" 
            r="60" 
            cx="88" 
            cy="88" 
          />
          <text 
            x="50%" 
            y="50%" 
            textAnchor="middle" 
            dy=".3em" 
            className="text-3xl font-bold text-gray-700"
          >
            {typeof valor === 'number' ? Math.round(valor) : valor}
          </text>
          <text 
            x="50%" 
            y="65%" 
            textAnchor="middle" 
            dy=".3em" 
            className="text-lg text-gray-700 mb-2"
          >
            {unidad}
          </text>
        </svg>
      </div>
    </div>
  </div>
);

const BeneficiosCarencia = ({ tipoCBP, years }) => {
  const beneficios = tipoCBP === 'nuevo' ? {
    titulo: 'Beneficios del Nuevo CBP (RD 19/2022)',
    items: [
      'Reducción del 0,5% sobre el interés actual',
      'Posibilidad de tipo fijo según entidad',
      'Carencia durante 12 meses',
      'Ampliación hasta 7 años (máximo 40 años)'
    ]
  } : {
    titulo: 'Beneficios del CBP Antiguo (RD 6/2012)',
    items: [
      years === 5 ? 'Reducción a euribor -0,10' : 'Reducción del 0,5% sobre el interés actual',
      `Carencia de ${years} ${years === 1 ? 'año' : 'años'}`,
      'Ampliación hasta 40 años',
      'Posibilidad de una segunda carencia en el futuro'
    ]
  };

  return (
    <div className="mt-6 bg-white rounded-lg p-4 shadow-sm">
      <h3 className="text-lg font-bold mb-3">{beneficios.titulo}</h3>
      <ul className="space-y-2">
        {beneficios.items.map((item, index) => (
          <li key={index} className="flex items-center text-gray-700">
            <CheckCircleIcon className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ResultadoCarencia = ({
  years,
  interesCarencia,
  cuotaNuevaCarencia,
  pagoMensualHipoteca,
  interesAnual,
  ahorroCarencia,
  handleModalOpen,
  navigateToPricing,
  tipoCBP,
  formData,
  carencia
}) => {
  // Cálculos para los círculos de progreso
  const cuotaCompleta = cuotaNuevaCarencia >= pagoMensualHipoteca;
  const dashoffset = { 5: 0, 2: 113.097, 1: 169.646 };
  const strokeDashoffsetCuota = cuotaCompleta 
    ? dashoffset[years] 
    : 377 - (377 * (cuotaNuevaCarencia / pagoMensualHipoteca));
  const safeStrokeDashoffsetCuota = isNaN(strokeDashoffsetCuota) ? 0 : strokeDashoffsetCuota;

  const interesCompleto = interesCarencia >= interesAnual;
  const strokeDashoffsetInteres = interesCompleto 
    ? 0 
    : 377 - (377 * (interesCarencia / interesAnual));
  const safeStrokeDashoffsetInteres = isNaN(strokeDashoffsetInteres) ? 0 : strokeDashoffsetInteres;

  return (
    <div className="text-left bg-gray-100 p-6 rounded-lg shadow-md">
      <div className="bg-white rounded-lg p-4 mb-6">
        <h2 className="text-2xl font-bold text-green-600 mb-4">¡Enhorabuena!</h2>
        <p className="text-lg text-gray-700">
          Te puedes acoger al {tipoCBP === 'nuevo' ? 'nuevo' : 'antiguo'} Código de Buenas Prácticas 
          y conseguir una carencia de 
          <span className="font-bold"> {years} {years === 1 ? 'año' : 'años'} </span>
          pagando una cuota de
          <span className="font-bold"> {Math.round(cuotaNuevaCarencia)}€/mes</span>.
          Te puedes ahorrar 
          <span className="font-bold"> {ahorroCarencia}€ </span>
          durante el periodo de carencia.
        </p>
      </div>

      <div className="flex flex-col md:flex-row justify-center mb-6">
        <MetricaCircular
          titulo="Carencia"
          valor={years}
          unidad={years === 1 ? 'año' : 'años'}
          dashoffset={dashoffset[years]}
        />

        <MetricaCircular
          titulo="Nueva cuota"
          valor={Math.round(cuotaNuevaCarencia)}
          unidad="€/mes"
          dashoffset={safeStrokeDashoffsetCuota}
        />

        <MetricaCircular
          titulo="Interés carencia"
          valor={interesCarencia}
          unidad="%"
          dashoffset={safeStrokeDashoffsetInteres}
        />
      </div>

      <BeneficiosCarencia tipoCBP={tipoCBP} years={years} />

      <div className="space-y-4 mt-6">
        <div className="flex justify-start items-center">
          <InformationCircleIcon className="w-10 h-10 text-purple-500 mr-2" />
          <span className="text-lg font-bold">Explicación carencia</span>
          <ArrowRightIcon 
            className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer ml-2" 
            onClick={() => handleModalOpen('Explicación carencia')} 
          />
        </div>

        <div className="flex justify-start items-center">
          <CalculatorIcon className="w-10 h-10 text-purple-500 mr-2" />
          <span className="text-lg font-bold">Calcular cuota después de carencia</span>
          <ArrowRightIcon 
            className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer ml-2" 
            onClick={() => handleModalOpen('calculador-cuota')} 
          />
        </div>

        <div className="flex justify-start items-center">
          <ClipboardCheckIcon className="w-10 h-10 text-purple-500 mr-2" />
          <span className="text-lg font-bold">Revisa tus respuestas</span>
          <ArrowRightIcon 
            className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer ml-2" 
            onClick={() => handleModalOpen('Revisa tus respuestas')} 
          />
        </div>
      </div>

      <button 
        className="mt-6 bg-purple-600 text-white py-4 px-8 rounded-full text-lg w-full md:w-auto
          hover:bg-purple-700 transition-colors duration-200"
        onClick={navigateToPricing}
      >
        Acógete ahora y baja la cuota de tu hipoteca
      </button>
    </div>
  );
};

export default ResultadoCarencia;