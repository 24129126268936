import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import supabase from './services/supabaseClient';
import bankOptions from '../data/bankOptions';
import provincias from '../data/Provincias';

// Importación de componentes
import FormularioSimulacion from './FormularioSimulacion';
import ResultadoCarencia from './ResultadoCarencia';
import PantallaExclusion from './PantallaExclusion';
import ModalInformacion from './ModalInformacion';

const initialFormData = {
  // Datos básicos
  precioVivienda: '',
  firmasteHipoteca: '',
  viviendaHabitual: '',
  fechaSubasta: '',

  // Datos bancarios
  bancoHipoteca: '',
  ingresosBrutosAnuales: '',
  ingresosNetosAnuales: '',
  pagoMensualHipoteca: '',
  cantidadPorPagar: '',
  anosFaltantes: '',
  plazoOriginalHipoteca: '',
  interesAnual: '',
  cuotaHace4Anos: '',
  cuotaJunio2022: '',
  ingresosHace4Anos: '',

  // Datos de vulnerabilidad
  gradoDiscapacidad: '',
  mayor60: '',
  dificultadesPago: '',
  grupoFamiliar: '',

  // Datos personales
  nombre: '',
  apellidos: '',
  telefono: '',
  provincia: '',
  politicaPrivacidad: false,
};

const questions = [
  {
    label: '¿El precio de tu vivienda es inferior a 300.000€?',
    name: 'precioVivienda',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'El precio de adquisición de la vivienda debe ser inferior a 300.000€ para cumplir los requisitos.'
  },
  {
    label: '¿Firmaste tu hipoteca antes del 31 de diciembre de 2022?',
    name: 'firmasteHipoteca',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Debe haber firmado su hipoteca antes del 31 de diciembre de 2022 para calificar.'
  },
  {
    label: '¿Es su vivienda habitual?',
    name: 'viviendaHabitual',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Debe ser su vivienda principal y habitual y estar empadronado'
  },
  {
    label: '¿Tiene su hipoteca fecha de subasta?',
    name: 'fechaSubasta',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Si su hipoteca tiene fecha de subasta no podrá acogerse al Código de Buenas Prácticas.'
  },

  {
    label: '¿Con qué banco tiene la hipoteca? Busca la hipoteca en la lista.',
    name: 'bancoHipoteca',
    type: 'autocomplete',
    options: bankOptions,
    explanation: 'Seleccione el banco con el que tiene su hipoteca.'
  },
  {
    label: '¿Existe algún grado de discapacidad en la unidad familiar?',
    name: 'gradoDiscapacidad',
    type: 'select',
    options: [
      'No',
      'Sí, superior al 33%',
      'Sí, superior al 65%',
      'Sí, discapacidad intelectual o enfermedad mental igual o superior al 33%',
      'Sí, discapacidad física o sensorial igual o superior al 65%'
    ],
    explanation: 'El grado de discapacidad afecta al límite de ingresos permitido y a la consideración de familia vulnerable.'
  },
  {
    label: '¿Cuáles son los ingresos Brutos Anuales de tu unidad familiar?',
    name: 'ingresosBrutosAnuales',
    type: 'number',
    explanation: 'Suma todos los ingresos brutos (antes de impuestos) de la unidad familiar durante un año.'
  },
  {
    label: '¿Cuáles son los ingresos Netos Anuales de tu unidad familiar?',
    name: 'ingresosNetosAnuales',
    type: 'number',
    explanation: 'Suma los ingresos netos (después de impuestos) que recibe tu unidad familiar durante un año.'
  },

  // ... continuación de las preguntas
  {
    label: '¿Es el deudor mayor de 60 años?',
    name: 'mayor60',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'La edad del deudor puede afectar a la consideración de familia vulnerable.'
  },
  {
    label: '¿Ha tenido dificultades para pagar la cuota de su hipoteca en los últimos 4 años?',
    name: 'dificultadesPago',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Indique si ha tenido problemas para pagar la hipoteca en los últimos 4 años.'
  },
  {
    label: '¿Su familia pertenece a alguno de estos grupos: familias numerosas, monoparentales, con menores, o víctimas de violencia?',
    name: 'grupoFamiliar',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Seleccione si su familia pertenece a alguno de estos grupos específicos.'
  },
  {
    label: '¿Cuánto pagas de hipoteca al mes?',
    name: 'pagoMensualHipoteca',
    type: 'number',
    explanation: 'Indica cuánto pagas mensualmente por tu hipoteca.'
  },
  {
    label: '¿Cuánto dinero te queda por pagar de la hipoteca (cuantía total)?',
    name: 'cantidadPorPagar',
    type: 'number',
    explanation: 'Indica el importe pendiente total, puedes verlo en la app del banco o en tu último recibo.'
  },
  {
    label: '¿Cuántos años le faltan para pagar la hipoteca?',
    name: 'anosFaltantes',
    type: 'number',
    explanation: 'Indica cuántos años te faltan para terminar de pagar la hipoteca.'
  },
  {
    label: '¿Cuál es el plazo original de tu hipoteca?',
    name: 'plazoOriginalHipoteca',
    type: 'number',
    explanation: 'Indica el plazo original en años que estableciste al firmar la hipoteca.'
  },
  {
    label: '¿Qué interés te están aplicando actualmente?',
    name: 'interesAnual',
    type: 'number',
    explanation: 'Indica el interés actual (Euribor + diferencial) que estás pagando.'
  },
  {
    label: '¿Cuánto pagabas mensualmente de cuota hace 4 años?',
    name: 'cuotaHace4Anos',
    type: 'number',
    explanation: 'Indica la cuota que pagabas hace 4 años para calcular el incremento.'
  },
  {
    label: '¿Cuánto pagabas de cuota en junio 2022?',
    name: 'cuotaJunio2022',
    type: 'number',
    explanation: 'Necesitamos saber la cuota que pagabas en junio 2022 para calcular el beneficio de la carencia.'
  },
  {
    label: '¿Cuánto eran tus Ingresos Netos Anuales hace 4 años?',
    name: 'ingresosHace4Anos',
    type: 'number',
    explanation: 'Indica tus ingresos netos anuales de hace 4 años para calcular el incremento del esfuerzo.'
  }
];

function SimulacionCBP() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  // Estados
  const [estado, setEstado] = useState('en curso');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredProvinces, setFilteredProvinces] = useState([]);
  const [error, setError] = useState('');
  const [exclusion, setExclusion] = useState(false);
  const [failedCriteria, setFailedCriteria] = useState([]);
  const [vulnerable, setVulnerable] = useState(false);
  const [interesCarencia, setInteresCarencia] = useState(0);
  const [cuotaNuevaCarencia, setCuotaNuevaCarencia] = useState(0);
  const [ahorroCarencia, setAhorroCarencia] = useState(0);
  const [carencia, setCarencia] = useState('');

  // Función para calcular 'vulnerable' basada en 'formData'
  const computeVulnerable = (formData) => {
    return (
      (formData.gradoDiscapacidad && formData.gradoDiscapacidad !== 'No') ||
      formData.mayor60 === 'Sí' ||
      formData.grupoFamiliar === 'Sí'
    );
  };

  // Efecto principal que maneja la carga de datos, guardado y cálculos
  useEffect(() => {
    const loadAndProcessData = async () => {
      // Carga inicial de datos si el usuario está autenticado
      if (isLoading && user) {
        try {
          const { data, error } = await supabase
            .from('user_progress')
            .select('*')
            .eq('user_id', user.id)
            .single();

          if (data) {
            setFormData(data.formData);
            setCurrentQuestion(data.current_question);
            setEstado(data.estado);
          } else if (error) {
            console.error('Error loading user progress:', error);
          }
        } catch (error) {
          console.error('Error in loading data:', error);
        } finally {
          setIsLoading(false);
        }
      }

      // Guardar progreso cuando los datos cambien
      if (!isLoading && user) {
        try {
          const { error } = await supabase
            .from('user_progress')
            .upsert({
              user_id: user.id,
              formData,
              current_question: currentQuestion,
              updated_at: new Date().toISOString(),
              nombre: formData.nombre,
              apellidos: formData.apellidos,
              telefono: formData.telefono,
              estado,
            });

          if (error) throw error;

          await supabase
            .from('customer_journey')
            .upsert({
              user_id: user.id,
              estado: estado,
              timestamp: new Date().toISOString(),
              updated_at: new Date().toISOString()
            }, { onConflict: 'user_id' });

        } catch (error) {
          console.error('Error saving progress:', error);
        }
      }

      // Recalcular 'vulnerable' cuando 'formData' cambie
      const isVulnerable = computeVulnerable(formData);
      setVulnerable(isVulnerable);

      // Cálculos de carencia y beneficios
      if (!isLoading) {
        const newScreen = determineScreen(formData, isVulnerable);
        let years = 0;
        let nuevoInteres = 0;

        switch (newScreen) {
          case 'carencia5':
            nuevoInteres = parseFloat((2.6 - 0.10).toFixed(2)); // Formatea a 2 decimales
            years = 5;
            break;
          case 'carencia2':
            nuevoInteres = parseFloat((formData.interesAnual - 0.5).toFixed(2));
            years = 2;
            break;
          case 'carencia1':
            nuevoInteres = parseFloat((formData.interesAnual - 0.5).toFixed(2));
            years = 1;
            break;
          default:
            break;
        }

        setInteresCarencia(nuevoInteres);

        let nuevaCuota = (formData.cantidadPorPagar * (nuevoInteres / 100)) / 12;
        if (newScreen === 'carencia1' && nuevaCuota < formData.cuotaJunio2022) {
          nuevaCuota = formData.cuotaJunio2022;
        }

        setCuotaNuevaCarencia(Math.round(nuevaCuota));
        const ahorro = (formData.pagoMensualHipoteca - nuevaCuota) * 12 * years;
        setAhorroCarencia(ahorro.toLocaleString('es-ES', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
        setCarencia(newScreen);
      }
    };

    loadAndProcessData();
  }, [isLoading, user, formData, currentQuestion, estado]);

  // Función para determinar el tipo de carencia sin dependencia de 'tipoCBP'
  const determineScreen = (formData, vulnerable) => {
    console.log('=== ANÁLISIS CÓDIGO BUENAS PRÁCTICAS ===');

    console.log('\n=== SITUACIÓN FAMILIAR ===');
    console.log(`¿Es familia vulnerable?: ${vulnerable}`);
    if (vulnerable) {
      console.log('Motivos posibles de vulnerabilidad:');
      console.log('- Discapacidad > 33%');
      console.log('- Mayor de 60 años');
      console.log('- Familia numerosa');
      console.log('- Familia monoparental');
      console.log('- Menor de 4 años');
      console.log('- Víctima violencia género');
    }

    if (!formData.ingresosNetosAnuales || !formData.pagoMensualHipoteca) {
      console.log('Faltan datos básicos de ingresos o cuota mensual');
      return 'exclusion';
    }

    // Cálculos base
    const monthlyNetosAnuales = formData.ingresosNetosAnuales / 12;
    const monthlyIngresosHace4Anos = formData.ingresosHace4Anos / 12;

    // Tasa de esfuerzo actual
    const currentEffortRate = formData.pagoMensualHipoteca / monthlyNetosAnuales;

    // Tasa de esfuerzo hace 4 años
    const historicalEffortRate = formData.cuotaHace4Anos / monthlyIngresosHace4Anos;

    // Incremento de la tasa de esfuerzo
    const effortIncrease = currentEffortRate / historicalEffortRate;

    console.log('\n=== CATEGORÍAS DE CARENCIA ===');
    console.log('CARENCIA 5 AÑOS:');
    console.log('- Requiere tasa esfuerzo ≥ 50% Y (ser vulnerable O tener incremento > 1.5x)');
    console.log('CARENCIA 2 AÑOS:');
    console.log('- Tasa esfuerzo entre 50% y 50%');
    console.log('- O tasa esfuerzo ≥ 50% sin cumplir condiciones adicionales');
    console.log('CARENCIA 1 AÑO:');
    console.log('- Tasa esfuerzo entre 30% y 50%\n');

    console.log('\n=== CÁLCULOS DE ESFUERZO ===');
    console.log(`1. ESFUERZO ACTUAL: ${(currentEffortRate * 100).toFixed(2)}%`);
    console.log(`   Cuota: ${formData.pagoMensualHipoteca}€`);
    console.log(`   Ingresos mensuales: ${monthlyNetosAnuales.toFixed(2)}€`);

    console.log(`\n2. ESFUERZO HACE 4 AÑOS: ${(historicalEffortRate * 100).toFixed(2)}%`);
    console.log(`   Cuota: ${formData.cuotaHace4Anos}€`);
    console.log(`   Ingresos mensuales: ${monthlyIngresosHace4Anos.toFixed(2)}€`);

    console.log(`\n3. INCREMENTO TASA ESFUERZO: ${effortIncrease.toFixed(2)}x`);

    console.log('\n=== EVALUACIÓN CARENCIA 5 AÑOS ===');
    const isHighEffort = currentEffortRate >= 0.5;
    const hasSignificantIncrease = effortIncrease > 1.5;

    console.log('Requisitos:');
    console.log(`1. Tasa esfuerzo actual >= 50%: ${isHighEffort}`);
    if (isHighEffort) {
      console.log('2. Además, debe cumplir al menos una:');
      console.log(`   - Es vulnerable: ${vulnerable}`);
      if (vulnerable) {
        console.log('     ✓ Cumple por ser familia vulnerable');
      }
      console.log(`   - Incremento tasa > 1.5x: ${hasSignificantIncrease}`);
      if (hasSignificantIncrease) {
        console.log('     ✓ Cumple por tener incremento significativo');
      }
    }

    // Lógica de decisión con explicación detallada de vulnerabilidad
    if (isHighEffort && (vulnerable || hasSignificantIncrease)) {
      console.log('\nRESULTADO -> CARENCIA 5 AÑOS');
      console.log('Motivo: Cumple tasa esfuerzo ≥ 50% y:');
      if (vulnerable) console.log('- Es familia vulnerable');
      if (hasSignificantIncrease) console.log('- Tiene incremento significativo >1.5x');
      return 'carencia5';
    } else if (currentEffortRate >= 0.5) {
      console.log('\nRESULTADO -> CARENCIA 2 AÑOS');
      if (vulnerable) {
        console.log('Nota: Aunque es familia vulnerable, no obtiene carencia 5 años por no cumplir tasa esfuerzo ≥ 50%');
      }
      console.log(`Motivo: Tasa esfuerzo actual es ${(currentEffortRate * 100).toFixed(2)}% (≥ 50% sin condiciones adicionales)`);
      return 'carencia2';
    } else if (currentEffortRate >= 0.3) {
      console.log('\nRESULTADO -> CARENCIA 1 AÑO');
      if (vulnerable) {
        console.log('Nota: Aunque es familia vulnerable, no obtiene carencia superior por no cumplir tasa esfuerzo mínima');
      }
      console.log(`Motivo: Tasa esfuerzo actual es ${(currentEffortRate * 100).toFixed(2)}% (entre 30% y 50%)`);
      return 'carencia1';
    }

    console.log('\nRESULTADO -> NO APLICA');
    if (vulnerable) {
      console.log('Nota: Aunque es familia vulnerable, no obtiene carencia por no cumplir tasa esfuerzo mínima');
    }
    console.log('Motivo: No cumple criterios mínimos de esfuerzo');
    return 'exclusion';
  };
  // Manejadores de eventos
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    // Actualizar el formData
    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));

    // Manejo de criterios de exclusión
    const newFailedCriteria = [...failedCriteria];

    // Función auxiliar para determinar límite de ingresos
    const determinarLimiteIngresos = (discapacidad) => {
      const limites = {
        'base': 37800,
        'discapacidad33': 46200,
        'discapacidad65': 54600
      };

      if (discapacidad?.includes('65%') ||
        discapacidad?.includes('física o sensorial')) {
        return limites.discapacidad65;
      } else if (discapacidad?.includes('33%') ||
        discapacidad?.includes('intelectual') ||
        discapacidad?.includes('mental')) {
        return limites.discapacidad33;
      }
      return limites.base;
    };

    // Manejo de criterios básicos (no ingresos)
    if (['fechaSubasta', 'precioVivienda', 'firmasteHipoteca', 'viviendaHabitual'].includes(name)) {
      if ((name === 'fechaSubasta' && value === 'Sí') ||
        (name !== 'fechaSubasta' && value === 'No')) {
        if (!newFailedCriteria.includes(name)) {
          newFailedCriteria.push(name);
        }
      } else {
        const index = newFailedCriteria.indexOf(name);
        if (index > -1) {
          newFailedCriteria.splice(index, 1);
        }
      }
    }

    // Manejo de ingresos y discapacidad
    if (name === 'gradoDiscapacidad' || name === 'ingresosBrutosAnuales') {
      const updatedFormData = {
        ...formData,
        [name]: value
      };

      // Solo verificar ingresos si tenemos todos los datos necesarios
      if (updatedFormData.ingresosBrutosAnuales &&
        (updatedFormData.gradoDiscapacidad || name === 'ingresosBrutosAnuales')) {
        const limiteIngresos = determinarLimiteIngresos(
          updatedFormData.gradoDiscapacidad
        );

        if (parseFloat(updatedFormData.ingresosBrutosAnuales) > limiteIngresos) {
          if (!newFailedCriteria.includes('ingresosBrutosAnuales')) {
            newFailedCriteria.push('ingresosBrutosAnuales');
          }
        } else {
          const index = newFailedCriteria.indexOf('ingresosBrutosAnuales');
          if (index > -1) {
            newFailedCriteria.splice(index, 1);
          }
        }
      }
    }

    // Manejo de autocompletado para banco
    if (name === 'bancoHipoteca') {
      const question = questions.find((q) => q.name === 'bancoHipoteca');
      if (question) {
        setFilteredOptions(
          question.options.filter((option) =>
            option.toLowerCase().includes(value.toLowerCase())
          )
        );
        if (!question.options.includes(value)) {
          setError('Por favor seleccione una opción válida.');
        } else {
          setError('');
        }
      }
    }

    // Manejo de autocompletado para provincia
    if (name === 'provincia') {
      setFilteredProvinces(
        provincias.filter((provincia) =>
          provincia.toLowerCase().includes(value.toLowerCase())
        )
      );
    }

    // Debug logs
    console.log('Campo cambiado:', name, 'Valor:', value);
    console.log('Formulario actualizado:', { ...formData, [name]: value });
    console.log('Criterios de exclusión:', newFailedCriteria);
    console.log('Estado de exclusión:', newFailedCriteria.length > 0);

    // Actualizar estados
    setFailedCriteria(newFailedCriteria);
    setExclusion(newFailedCriteria.length > 0);
  };

  const handleNext = () => {
    const currentQuestionObj = questions[currentQuestion];
    if (currentQuestionObj.name === 'bancoHipoteca' &&
      !bankOptions.includes(formData.bancoHipoteca)) {
      setModalContent('Por favor seleccione una opción válida.');
      setIsModalOpen(true);
      return;
    }
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrev = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.politicaPrivacidad) {
      setError('Debes aceptar la política de privacidad para continuar.');
      return;
    }

    try {
      await supabase
        .from('simulacion_resultados')
        .insert({
          id: user.id,
          email: user.email,
          ...formData,
          Carencia: carencia,
          NuevaCuota: cuotaNuevaCarencia,
          InteresCarencia: interesCarencia
        });

      setEstado('Lead simulador completo');
    } catch (error) {
      console.error('Error al guardar los datos:', error);
      setError('Hubo un error al guardar los datos. Por favor, inténtalo de nuevo.');
    }
  };

  const handleModalOpen = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  const handleRetry = () => {
    window.location.reload();
  };

  const navigateToPricing = () => {
    navigate(`/pricing-${carencia}`);
  };

  if (isLoading) {
    return (
      <div className="flex min-h-screen bg-gray-50 justify-center items-center">
        Cargando...
      </div>
    );
  }

  // Renderizado del componente
  return (
    <div className="flex min-h-screen bg-gray-50 overflow-y-auto">
      <div className="flex-grow p-6 mt-16 md:mt-0 flex justify-center items-center">
        <div className="bg-white shadow-lg rounded-lg p-2 w-full max-w-lg md:max-w-2xl lg:max-w-3xl">
          {estado === 'en curso' ? (
            exclusion ? (
              <PantallaExclusion
                formData={formData}
                calculateExclusionProgress={() =>
                  ((4 - failedCriteria.length) / 4) * 100}
                handleRetry={handleRetry}
                mostrarResumen={false}
              />
            ) : (
              <FormularioSimulacion
                questions={questions}
                currentQuestion={currentQuestion}
                formData={formData}
                handleChange={handleChange}
                handleNext={handleNext}
                handlePrev={handlePrev}
                handleSubmit={handleSubmit}
                handleModalOpen={handleModalOpen}
                filteredOptions={filteredOptions}
                filteredProvinces={filteredProvinces}
                handleOptionClick={(option) => {
                  setFormData({ ...formData, bancoHipoteca: option });
                  setFilteredOptions([]);
                }}
                handleProvinceClick={(provincia) => {
                  setFormData({ ...formData, provincia });
                  setFilteredProvinces([]);
                }}
                error={error}
                progress={(currentQuestion / questions.length) * 100}
              />
            )
          ) : (
            carencia !== 'exclusion' ? (
              <ResultadoCarencia
                years={carencia === 'carencia5' ? 5 : carencia === 'carencia2' ? 2 : 1}
                interesCarencia={parseFloat(interesCarencia).toFixed(2)}
                cuotaNuevaCarencia={cuotaNuevaCarencia}
                pagoMensualHipoteca={formData.pagoMensualHipoteca}
                interesAnual={formData.interesAnual}
                ahorroCarencia={ahorroCarencia}
                handleModalOpen={handleModalOpen}
                navigateToPricing={navigateToPricing}
                formData={formData} // Añadimos formData
                carencia={carencia} // Añadimos carencia
              />
            ) : (
              <PantallaExclusion
                formData={formData}
                calculateExclusionProgress={() =>
                  ((4 - failedCriteria.length) / 4) * 100}
                handleRetry={handleRetry}
                mostrarResumen={true}
              />
            )
          )}
        </div>
      </div>
      <ModalInformacion
        isModalOpen={isModalOpen}
        modalContent={modalContent}
        handleModalClose={handleModalClose}
        formData={formData}
        carencia={carencia}
      />
    </div>
  );
}

export default SimulacionCBP;