import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

function SuccessPage() {
  const { width, height } = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/primerospasos');
    }, 10000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <div className="flex min-h-screen bg-gray-50 overflow-y-auto">
      <div className="flex-grow p-6 mt-16 md:mt-0 flex justify-center items-center">
        <section className="pt-12 pb-12 bg-blueGray-50 overflow-hidden w-full">
          <div className="container sm:px-8 md:px-12 mx-auto">
            <Confetti width={width} height={height} />
            <h2 className="mb-12 text-4xl md:text-4xl font-bold text-center tracking-px-n leading-tight w-full">
              ¡Pago realizado con éxito!
            </h2>
            <p className="text-center">Gracias por tu compra. Tu pago ha sido procesado correctamente.</p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SuccessPage;
