// FormularioSimulacion.js

import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';

const FormularioSimulacion = ({
  questions,
  currentQuestion,
  formData,
  handleChange,
  handleNext,
  handlePrev,
  handleSubmit,
  handleModalOpen,
  filteredOptions,
  filteredProvinces,
  handleOptionClick,
  handleProvinceClick,
  error,
  progress
}) => {
  const renderInput = (question) => {
    switch (question.type) {
      case 'radio':
        return (
          <>
            {question.options.map((option) => (
              <div key={option} className="mb-2">
                <input
                  type="radio"
                  id={`${question.name}-${option}`}
                  name={question.name}
                  value={option}
                  checked={formData[question.name] === option}
                  onChange={handleChange}
                  className="hidden"
                />
                <label
                  htmlFor={`${question.name}-${option}`}
                  className={`block cursor-pointer py-2 px-4 rounded-md border text-center text-sm md:text-base lg:text-lg 
                    ${formData[question.name] === option 
                      ? 'bg-blue-600 text-white border-blue-600' 
                      : 'bg-blue-100 text-blue-600 border-blue-600'}`}
                >
                  {option}
                </label>
              </div>
            ))}
          </>
        );

      case 'select':
        return (
          <select
            name={question.name}
            value={formData[question.name]}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg"
          >
            <option value="">Seleccione una opción</option>
            {question.options.map((option) => (
              <option key={option} value={option}>
                {question.optionsText ? question.optionsText[option] : option}
              </option>
            ))}
          </select>
        );

      case 'autocomplete':
        return (
          <div className="relative">
            <input
              type="text"
              name={question.name}
              value={formData[question.name]}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg"
              placeholder="Escribe para buscar..."
            />
            {filteredOptions.length > 0 && (
              <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-60 overflow-y-auto">
                {filteredOptions.map((option) => (
                  <div
                    key={option}
                    className="py-2 px-4 hover:bg-gray-100 cursor-pointer text-sm md:text-base lg:text-lg"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        );

      case 'number':
        return (
          <input
            type="number"
            name={question.name}
            value={formData[question.name]}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg no-spin"
          />
        );

      default:
        return null;
    }
  };

  const renderContactForm = () => (
    <div className="space-y-4">
      <p className="mb-4 text-center text-gray-700 text-sm md:text-base lg:text-lg">
        Dejános tus datos y sabrás al instante si puedes rebajar la cuota de tu hipoteca.
      </p>
      <div className="space-y-2">
        <input
          type="text"
          name="nombre"
          value={formData.nombre}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg"
          placeholder="Nombre"
        />
        <input
          type="text"
          name="apellidos"
          value={formData.apellidos}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg"
          placeholder="Apellidos"
        />
        <input
          type="tel"
          name="telefono"
          value={formData.telefono}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg"
          placeholder="Número de Teléfono"
        />
        <div className="relative">
          <input
            type="text"
            name="provincia"
            value={formData.provincia}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg"
            placeholder="Provincia"
          />
          {filteredProvinces.length > 0 && (
            <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-60 overflow-y-auto">
              {filteredProvinces.map((provincia) => (
                <div
                  key={provincia}
                  className="py-2 px-4 hover:bg-gray-100 cursor-pointer text-sm md:text-base lg:text-lg"
                  onClick={() => handleProvinceClick(provincia)}
                >
                  {provincia}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center mt-4">
        <input
          type="checkbox"
          name="politicaPrivacidad"
          id="politicaPrivacidad"
          checked={formData.politicaPrivacidad}
          onChange={handleChange}
          className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
        />
        
      </div>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      <button
        type="submit"
        className={`py-2 px-6 rounded-md mt-4 text-sm md:text-base lg:text-lg w-full
          ${formData.politicaPrivacidad && formData.nombre && formData.apellidos && formData.telefono && formData.provincia
            ? 'bg-purple-600 text-white hover:bg-purple-700'
            : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
        disabled={!formData.politicaPrivacidad || !formData.nombre || !formData.apellidos || !formData.telefono || !formData.provincia}
      >
        Ver el resultado
      </button>
    </div>
  );

  return (
    <>
      <h2 className="text-2xl font-bold mb-4 text-center">Simulación CBP</h2>
      <div className="relative w-full h-2 bg-gray-300 rounded-full mb-6">
        <div
          className="absolute top-0 left-0 h-2 bg-purple-600 rounded-full"
          style={{ width: `${progress}%` }}
        />
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        {currentQuestion < questions.length ? (
          <div>
            <div className="flex justify-between items-center mb-4 relative">
              <label className="block text-gray-700 text-sm md:text-base lg:text-lg">
                {questions[currentQuestion].label}
              </label>
              <InformationCircleIcon
                className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer flex-shrink-0"
                onClick={() => handleModalOpen(questions[currentQuestion].explanation)}
              />
            </div>
            {renderInput(questions[currentQuestion])}
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handlePrev}
                className={`py-2 px-6 rounded-md text-sm md:text-base lg:text-lg 
                  ${currentQuestion === 0
                    ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                    : 'bg-purple-600 text-white hover:bg-purple-700'}`}
                disabled={currentQuestion === 0}
              >
                Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className={`py-2 px-6 rounded-md text-sm md:text-base lg:text-lg 
                  ${formData[questions[currentQuestion].name]
                    ? 'bg-purple-600 text-white hover:bg-purple-700'
                    : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
                disabled={!formData[questions[currentQuestion].name]}
              >
                Siguiente
              </button>
            </div>
          </div>
        ) : (
          renderContactForm()
        )}
      </form>
    </>
  );
};

export default FormularioSimulacion;