import React, { useState } from 'react';
import supabase from './services/supabaseClient';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'; // Importar el logo

function ResetPassword() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Incluir aquí la URL a la que debe redirigir a los usuarios después de hacer clic en el enlace de restablecimiento
            const redirectToUrl = 'http://localhost:3000/PasswordRecovery';  // Cambia esta URL por la tuya

            const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
                redirectTo: redirectToUrl
            });

            if (error) {
                console.error('Error enviando el correo para restablecer contraseña:', error.message);
                toast.error('Error al enviar el correo para restablecer contraseña. Por favor intenta de nuevo.', {
                    autoClose: 1000
                });
            } else {
                toast.success(`Un enlace para restablecer la contraseña ha sido enviado a ${email}. Sigue las instrucciones en el correo para resetear tu contraseña.`, {
                    autoClose: 1000,
                    onClose: () => navigate('/login')  // Redirigir después de mostrar el mensaje
                });
            }
        } catch (err) {
            console.error('Error en la solicitud de restablecimiento de contraseña:', err);
            toast.error('Error en la solicitud de restablecimiento de contraseña.', {
                autoClose: 1000
            });
        }
    };

    return (
        <section className="py-16 xl:pb-56 bg-white overflow-hidden">
            <div className="container px-4 mx-auto">
                <div className="text-center max-w-md mx-auto">
                    <a className="mb-36 inline-block" href="#">
                        <img src={logo} alt="Logo" />  {/* Usar el logo importado */}
                    </a>
                    <h2 className="mb-4 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight">Restablecer contraseña</h2>
                    <p className="mb-12 font-medium text-lg text-gray-600 leading-normal">Introduce tu correo electrónico para recibir las instrucciones para restablecer tu contraseña</p>
                    <form onSubmit={handleSubmit}>
                        <label className="block mb-5">
                            <input className="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300" type="email" placeholder="Correo Electrónico" name="email" value={email} onChange={handleChange} required />
                        </label>
                        <button className="mb-8 py-4 px-9 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="submit">Enviar instrucciones</button>
                    </form>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={1000}  // Mensajes se cierran automáticamente después de 1 segundo
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Slide}
            />
        </section>
    );
}

export default ResetPassword;
