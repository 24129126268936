import React, { useEffect, useRef, useContext } from 'react';
import stripeLogo from '../assets/stripe.svg';
import amexLogo from '../assets/amex.svg';
import mastercardLogo from '../assets/mastercard.svg';
import paypalLogo from '../assets/paypal.svg';
import visaLogo from '../assets/visa.svg';
import applePayLogo from '../assets/apple-pay.svg';
import { UserContext } from './UserContext';

function PricingPage() {
  const pricingTableRef = useRef(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      console.log('User ID:', user.id);
      console.log('Email:', user.email);
    } else {
      console.log('No user data');
    }

    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    script.onload = () => {
      if (user && pricingTableRef.current && !pricingTableRef.current.querySelector('stripe-pricing-table')) {
        const stripePricingTable = document.createElement('stripe-pricing-table');
        stripePricingTable.setAttribute('pricing-table-id', 'prctbl_1PLlPg2M8Z9g7a3hv5KNv0Uv');
        stripePricingTable.setAttribute('publishable-key', 'pk_test_51P9ZdS2M8Z9g7a3hGLMCJ8DA1dGP1gXCQsq87v4s3yzQJFlrDAXJX81huq0XggcpRITlxbKRSurCTfY5kDdVJSwz00NeojheGJ');
        stripePricingTable.setAttribute('client-reference-id', user.id);
        stripePricingTable.setAttribute('customer-email', user.email);
        stripePricingTable.style.width = '100%';
        pricingTableRef.current.appendChild(stripePricingTable);
      }
    };

    script.onerror = () => {
      console.error('Error loading Stripe script');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [user]);

  return (
    <div className="flex min-h-screen bg-gray-50 overflow-y-auto">
      <div className="flex-grow p-6 mt-16 md:mt-0 flex justify-center items-center">
        <section id="honorarios" className="pt-12 pb-12 bg-blueGray-50 overflow-hidden w-full">
          <div className="container sm:px-8 md:px-12 mx-auto">
            <h2 className="mb-12 text-4xl md:text-4xl font-bold text-center tracking-px-n leading-tight w-full">
              Elige una forma de pago para acogerte al código de buenas prácticas:
            </h2>
            
            <div className="mb-24 max-w-full mx-auto">
              <div className="flex flex-wrap">
                <div className="w-full">
                  <div className="flex flex-col sm:px-6 md:px-9 pt-8 pb-9 h-full bg-grey" 
                    style={{ backdropFilter: 'blur(46px)' }}
                    ref={pricingTableRef}
                  >
                    {/* El contenido de Stripe se añadirá aquí */}
                  </div>
                </div>
              </div>
            </div>
            <p className="mb-4 text-sm text-gray-500 text-center font-medium leading-relaxed">Servicio de pago seguro</p>
            <div className="flex flex-wrap justify-center">
              <div className="w-auto">
                <a href="#">
                  <img src={stripeLogo} alt="Stripe" />
                </a>
              </div>
              <div className="w-auto">
                <a href="#">
                  <img src={amexLogo} alt="Amex" />
                </a>
              </div>
              <div className="w-auto">
                <a href="#">
                  <img src={mastercardLogo} alt="Mastercard" />
                </a>
              </div>
              <div className="w-auto">
                <img src={paypalLogo} alt="Paypal" />
              </div>
              <div className="w-auto">
                <a href="#">
                  <img src={visaLogo} alt="Visa" />
                </a>
              </div>
              <div className="w-auto">
                <a href="#">
                  <img src={applePayLogo} alt="Apple Pay" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PricingPage;
