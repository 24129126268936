import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FiHome, FiFileText, FiCheckCircle, FiEdit,
  FiMessageSquare, FiCheck, FiMenu, FiX
} from 'react-icons/fi';
import logo from '../assets/logo.png';
import UserIcon from './UserIcon'; // Importa el componente UserIcon
import { UserContext } from './UserContext';
import supabase from './services/supabaseClient';

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [estado, setEstado] = useState('');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserState = async () => {
      if (user) {
        const { data, error } = await supabase
          .from('customer_journey')
          .select('estado')
          .eq('user_id', user.id)
          .order('timestamp', { ascending: false })
          .limit(1)
          .single();

        if (error) {
          console.error(error);
        } else {
          setEstado(data.estado);
        }
      }
    };

    fetchUserState();
  }, [user]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = isOpen ? 'auto' : 'hidden'; // Desactivar el desplazamiento cuando el sidebar esté abierto
  };

  const closeSidebar = () => {
    setIsOpen(false);
    document.body.style.overflow = 'auto'; // Restaurar el desplazamiento
  };

  // Efecto para limpiar el estilo del body al desmontar el componente
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleLinkClick = (path) => {
    switch (estado) {
      case 'Cliente Primeros Pasos':
        if (path === '/simulacioncbp') {
          navigate('/simuladorcompletado');
          return;
        }
        break;
      case 'Cliente Subir Doc':
        if (path === '/primerospasos') {
          navigate('/primerospasoscompletado');
          return;
        }
        break;
      case 'Cliente Firmar Documentación':
        if (path === '/subirdocumentacion') {
          navigate('/documentacionsubida');
          return;
        }
        break;
      default:
        break;
    }
    navigate(path);
  };

  const isLinkDisabled = (requiredEstado) => {
    const estadosOrden = [
      'Cliente Primeros Pasos',
      'Cliente Subir Doc',
      'Cliente Firmar Documentación',
      'Cliente Como Va mi Caso',
      'Cliente Caso Resuelto'
    ];
    const currentIndex = estadosOrden.indexOf(estado);
    const requiredIndex = estadosOrden.indexOf(requiredEstado);
    return currentIndex < requiredIndex;
  };

  return (
    <div className="flex min-h-screen">
      {/* Button for Mobile Menu */}
      <div className="fixed top-0 right-0 left-0 pt-4 p-4 flex justify-between items-center md:hidden z-50">
        <Link to="/">
          <img className="h-10" src={logo} alt="Logo" />
        </Link>
        <div className="flex items-center space-x-4">
          <UserIcon /> {/* User icon in the mobile menu */}
          <button onClick={toggleSidebar} className="text-gray-700 focus:outline-none">
            {isOpen ? <FiX className="w-6 h-6" /> : <FiMenu className="w-6 h-6" />}
          </button>
        </div>
      </div>

      {/* Sidebar */}
      <div className={`fixed inset-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0
      md:static md:inset-0 w-64 bg-white shadow-md border-r border-gray-200 transition-transform duration-300 ease-in-out flex flex-col z-40`}>
        <div className="p-6">
          {/* Mostrar el logo sólo en versión escritorio */}
          <div className="hidden md:block">
            <Link to="/">
              <img className="h-10 mx-auto" src={logo} alt="Logo" />
            </Link>
          </div>
        </div>
        <ul className="space-y-2 px-6 flex-grow p-6">
          <li className="flex items-center space-x-3">
            <FiHome className="w-5 h-5" />
            <button 
              onClick={() => handleLinkClick('/simulacioncbp')} 
              className="hover:bg-gray-100 rounded-md w-full py-2 text-left"
              disabled={estado === ''}
            >
              Simulación CBP
            </button>
          </li>
          <li className="flex items-center space-x-3">
            <FiFileText className="w-5 h-5" />
            <button 
              onClick={() => handleLinkClick('/primerospasos')} 
              className={`hover:bg-gray-100 rounded-md w-full py-2 text-left ${isLinkDisabled('Cliente Primeros Pasos') ? 'text-gray-400 cursor-not-allowed' : ''}`}
              disabled={isLinkDisabled('Cliente Primeros Pasos')}
            >
              Primeros Pasos
            </button>
          </li>
          <li className="flex items-center space-x-3">
            <FiCheckCircle className="w-5 h-5" />
            <button 
              onClick={() => handleLinkClick('/subirdocumentacion')} 
              className={`hover:bg-gray-100 rounded-md w-full py-2 text-left ${isLinkDisabled('Cliente Subir Doc') ? 'text-gray-400 cursor-not-allowed' : ''}`}
              disabled={isLinkDisabled('Cliente Subir Doc')}
            >
              Subir Documentación
            </button>
          </li>
          <li className="flex items-center space-x-3">
            <FiEdit className="w-5 h-5" />
            <button 
              onClick={() => handleLinkClick('/firmarautorizacion')} 
              className={`hover:bg-gray-100 rounded-md w-full py-2 text-left ${isLinkDisabled('Cliente Firmar Documentación') ? 'text-gray-400 cursor-not-allowed' : ''}`}
              disabled={isLinkDisabled('Cliente Firmar Documentación')}
            >
              Firmar Autorización
            </button>
          </li>
          <li className="flex items-center space-x-3">
            <FiMessageSquare className="w-5 h-5" />
            <button 
              onClick={() => handleLinkClick('/comovamicasso')} 
              className={`hover:bg-gray-100 rounded-md w-full py-2 text-left ${isLinkDisabled('Cliente Como Va mi Caso') ? 'text-gray-400 cursor-not-allowed' : ''}`}
              disabled={isLinkDisabled('Cliente Como Va mi Caso')}
            >
              ¿Cómo Va Mi Caso?
            </button>
          </li>
          <li className="flex items-center space-x-3">
            <FiCheck className="w-5 h-5" />
            <button 
              onClick={() => handleLinkClick('/casoresuelto')} 
              className={`hover:bg-gray-100 rounded-md w-full py-2 text-left ${isLinkDisabled('Cliente Caso Resuelto') ? 'text-gray-400 cursor-not-allowed' : ''}`}
              disabled={isLinkDisabled('Cliente Caso Resuelto')}
            >
              Caso Resuelto
            </button>
          </li>
        </ul>
      </div>

      {/* UserCard in the top right */}
      <div className="hidden md:flex fixed top-4 right-4 items-center space-x-4">
        <UserIcon />
      </div>
    </div>
  );
}

export default Sidebar;
