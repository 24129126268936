// PantallaExclusion.js

import React from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';

const CriterioExclusion = ({ cumple, texto }) => (
 <li className="flex items-center py-2">
   {cumple ? (
     <CheckCircleIcon className="w-6 h-6 text-green-500 mr-2 flex-shrink-0" />
   ) : (
     <XCircleIcon className="w-6 h-6 text-red-500 mr-2 flex-shrink-0" />
   )}
   <span className="text-gray-700">{texto}</span>
 </li>
);

const ResumenDatos = ({ label, valor }) => (
 <li className="py-3 border-b border-gray-200 last:border-b-0">
   <span className="font-semibold">{label}:</span>{' '}
   <span className="text-gray-700">{valor}</span>
 </li>
);

const SeccionResumen = ({ titulo, datos }) => (
 <div className="bg-white rounded-lg shadow-md p-6 mb-6">
   <h3 className="text-lg font-semibold mb-4 text-left">{titulo}</h3>
   <ul className="space-y-2 text-left">
     {datos.map((dato, index) => (
       <ResumenDatos
         key={index}
         label={dato.label}
         valor={dato.valor}
       />
     ))}
   </ul>
 </div>
);

const PantallaExclusion = ({ 
 formData, 
 calculateExclusionProgress, 
 handleRetry,
 mostrarResumen = false 
}) => {
 // Función para determinar el límite de ingresos según el tipo de CBP y discapacidad
 const determinarLimiteIngresos = () => {
   const limites = {
     'nuevo': {
       'base': 37800,
       'discapacidad33': 46200,
       'discapacidad65': 54600
     },
     'antiguo': {
       'base': 25200,
       'discapacidad33': 33600,
       'discapacidad65': 42000
     }
   };

   // Si no hay tipo de CBP, asumimos nuevo
   const tipoCBP = formData.tipoCBP || 'nuevo';

   if (!formData.gradoDiscapacidad || formData.gradoDiscapacidad === 'No') {
     return limites[tipoCBP].base;
   }

   if (formData.gradoDiscapacidad.includes('65%') || 
       formData.gradoDiscapacidad.includes('física o sensorial')) {
     return limites[tipoCBP].discapacidad65;
   }

   if (formData.gradoDiscapacidad.includes('33%') || 
       formData.gradoDiscapacidad.includes('intelectual') ||
       formData.gradoDiscapacidad.includes('mental')) {
     return limites[tipoCBP].discapacidad33;
   }

   return limites[tipoCBP].base;
 };

 const limiteIngresos = determinarLimiteIngresos();

 const getTextoIngresos = () => {
   let textoBase = `Los ingresos de la unidad familiar no superan los ${limiteIngresos.toLocaleString('es-ES')}€ anuales`;
   
   if (formData.gradoDiscapacidad && formData.gradoDiscapacidad !== 'No') {
     return `${textoBase} (límite aumentado por ${formData.gradoDiscapacidad})`;
   }
   
   return textoBase;
 };

 const criteriosBasicos = [
   {
     cumple: formData.fechaSubasta === 'No',
     texto: 'No debe tener fecha de subasta'
   },
   {
     cumple: formData.precioVivienda === 'Sí',
     texto: 'El precio de su vivienda es inferior o igual a 300.000€'
   },
   {
     cumple: formData.firmasteHipoteca === 'Sí',
     texto: 'Firmaste tu hipoteca antes del 31 de diciembre de 2022'
   },
   {
     cumple: formData.viviendaHabitual === 'Sí',
     texto: 'Es su vivienda habitual'
   },
   {
     cumple: !formData.ingresosBrutosAnuales || parseFloat(formData.ingresosBrutosAnuales) <= limiteIngresos,
     texto: getTextoIngresos()
   }
 ];

 const calcularRazonExclusion = () => {
   console.log('FormData en calcularRazonExclusion:', formData); // Debug
   
   if (formData.fechaSubasta === 'Sí') {
     return 'Tu hipoteca tiene fecha de subasta establecida';
   }
   if (formData.precioVivienda === 'No') {
     return 'El precio de tu vivienda supera los 300.000€';
   }
   if (formData.firmasteHipoteca === 'No') {
     return 'Tu hipoteca es posterior al 31 de diciembre de 2022';
   }
   if (formData.viviendaHabitual === 'No') {
     return 'La vivienda no es tu residencia habitual';
   }
   if (formData.ingresosBrutosAnuales && parseFloat(formData.ingresosBrutosAnuales) > limiteIngresos) {
     let mensaje = `Tus ingresos (${parseFloat(formData.ingresosBrutosAnuales).toLocaleString('es-ES')}€) superan el límite de ${limiteIngresos.toLocaleString('es-ES')}€ anuales`;
     
     if (formData.gradoDiscapacidad && formData.gradoDiscapacidad !== 'No') {
       mensaje += ` (teniendo en cuenta el incremento por ${formData.gradoDiscapacidad})`;
     }
     
     return mensaje;
   }
   return 'No cumples todos los requisitos necesarios';
 };

 const datosFamiliares = [
   { 
     label: 'Discapacidad', 
     valor: formData.gradoDiscapacidad || 'No' 
   },
   { 
     label: 'Mayor de 60 años', 
     valor: formData.mayor60 || 'No' 
   },
   { 
     label: 'Grupo familiar', 
     valor: formData.grupoFamiliar || 'No' 
   },
   {
     label: 'Tipo de CBP',
     valor: formData.tipoCBP === 'nuevo' ? 'Nuevo CBP' : 'CBP Antiguo'
   }
 ];

 const datosFinancieros = [
   { 
     label: 'Banco', 
     valor: formData.bancoHipoteca 
   },
   { 
     label: 'Ingresos brutos anuales', 
     valor: formData.ingresosBrutosAnuales ? `${parseFloat(formData.ingresosBrutosAnuales).toLocaleString('es-ES')}€` : 'No especificado' 
   },
   { 
     label: 'Cuota mensual actual', 
     valor: formData.pagoMensualHipoteca ? `${parseFloat(formData.pagoMensualHipoteca).toLocaleString('es-ES')}€` : 'No especificado' 
   },
   { 
     label: 'Cantidad pendiente', 
     valor: formData.cantidadPorPagar ? `${parseFloat(formData.cantidadPorPagar).toLocaleString('es-ES')}€` : 'No especificado' 
   },
   { 
     label: 'Años restantes', 
     valor: formData.anosFaltantes || 'No especificado' 
   },
   { 
     label: 'Interés actual', 
     valor: formData.interesAnual ? `${formData.interesAnual}%` : 'No especificado' 
   },
   { 
     label: 'Cuota hace 4 años', 
     valor: formData.cuotaHace4Anos ? `${parseFloat(formData.cuotaHace4Anos).toLocaleString('es-ES')}€` : 'No especificado' 
   },
   { 
     label: 'Ingresos hace 4 años', 
     valor: formData.ingresosHace4Anos ? `${parseFloat(formData.ingresosHace4Anos).toLocaleString('es-ES')}€` : 'No especificado' 
   }
 ];

 const datosPersonales = [
   { 
     label: 'Nombre', 
     valor: formData.nombre || 'No especificado' 
   },
   { 
     label: 'Apellidos', 
     valor: formData.apellidos || 'No especificado' 
   },
   { 
     label: 'Teléfono', 
     valor: formData.telefono || 'No especificado' 
   },
   { 
     label: 'Provincia', 
     valor: formData.provincia || 'No especificado' 
   }
 ];

 return (
   <div className="max-w-3xl mx-auto">
     <div className="text-center mb-8">
       <h2 className="text-2xl font-bold mb-4 text-red-600">Lo sentimos</h2>
       <p className="text-gray-700 mb-4">
         {calcularRazonExclusion()}
       </p>

       {/* Barra de progreso */}
       <div className="relative w-full h-2 bg-gray-300 rounded-full mb-6">
         <div 
           className="absolute top-0 left-0 h-2 bg-red-600 rounded-full transition-all duration-500" 
           style={{ width: `${calculateExclusionProgress()}%` }} 
         />
       </div>

       {/* Criterios básicos */}
       <div className="bg-white rounded-lg shadow-md p-6 mb-6">
         <h3 className="text-lg font-semibold mb-4 text-left">
           Requisitos básicos del CBP {formData.tipoCBP === 'nuevo' ? 'Nuevo' : 'Antiguo'}
         </h3>
         <ul className="space-y-4 text-left">
           {criteriosBasicos.map((criterio, index) => (
             <CriterioExclusion
               key={index}
               cumple={criterio.cumple}
               texto={criterio.texto}
             />
           ))}
         </ul>
       </div>

       {mostrarResumen && (
         <>
           <SeccionResumen titulo="Datos Familiares" datos={datosFamiliares} />
           <SeccionResumen titulo="Datos Financieros" datos={datosFinancieros} />
           <SeccionResumen titulo="Datos Personales" datos={datosPersonales} />

           <div className="bg-white rounded-lg shadow-md p-6 mb-6">
             <div className="text-left">
               <h3 className="text-lg font-semibold mb-4">
                 Información importante
               </h3>
               <p className="text-gray-700 mb-4">
                 Para poder acogerte al Código de Buenas Prácticas es necesario cumplir 
                 con todos los requisitos básicos. Te recomendamos:
               </p>
               <ul className="list-disc pl-5 space-y-2 text-gray-700">
                 <li>Verificar todos los datos proporcionados</li>
                 <li>Consultar con tu banco si tienes dudas sobre algún requisito</li>
                 <li>Considerar otras opciones de reestructuración de deuda</li>
               </ul>
             </div>
           </div>
         </>
       )}

       <button 
         onClick={handleRetry}
         className="mt-6 bg-purple-600 text-white py-3 px-8 rounded-md hover:bg-purple-700 
           transition-colors duration-200 w-full md:w-auto"
       >
         Revisar datos
       </button>
     </div>
   </div>
 );
};

export default PantallaExclusion;