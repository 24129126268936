import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import supabase from './services/supabaseClient';
import documentacionConfig from '../data/documentacionConfig.json';
import { FaFileAlt, FaFileInvoice, FaHome, FaUser } from 'react-icons/fa';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import StyledDropzone from './StyledDropzone';
import { UserContext } from './UserContext';
import { useNavigate } from 'react-router-dom';

const SubirDocumentacion = () => {
    const { user } = useContext(UserContext);
    const [familiares, setFamiliares] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(null);
    const [titulares, setTitulares] = useState([]);
    const [docUploadState, setDocUploadState] = useState({});
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [loadingDocumentacion, setLoadingDocumentacion] = useState(false);
    const [sessionInProgress, setSessionInProgress] = useState(false);
    const navigate = useNavigate();

    const normalizedDocumentacionConfig = {
        ...documentacionConfig,
        documentacion_ingresos: Object.fromEntries(
            Object.entries(documentacionConfig.documentacion_ingresos).map(
                ([key, value]) => [key.toLowerCase(), value]
            )
        )
    };

    const fetchFamiliares = useCallback(async () => {
        const { data, error } = await supabase
            .from('familiares')
            .select('*')
            .eq('cliente_id', user.id);

        if (error) {
            console.error('Error fetching familiares:', error);
        } else {
            const validData = data.map(familiar => ({
                ...familiar,
                relacion: familiar.relacion?.trim().toLowerCase() || 'otro',
                tipoIngreso: familiar.tipoIngreso?.trim().toLowerCase() || 'desconocido'
            }));

            setFamiliares(validData);

            const titulares = validData.filter(familiar => familiar.relacion && familiar.relacion === 'titular1');
            setTitulares(titulares);
            if (validData.length > 0) {
                setActiveTab(validData[0].id);
            }
        }
        setLoading(false);
    }, [user.id]);

    const fetchUploadedDocuments = useCallback(async () => {
        if (user && user.id) {
            const { data, error } = await supabase
                .from('documentos')
                .select('*')
                .eq('cliente_id', user.id);

            if (error) {
                console.error('Error fetching uploaded documents:', error);
            } else {
                const uploadedFilesMap = {};
                data.forEach(doc => {
                    if (!uploadedFilesMap[doc.familiar_id]) {
                        uploadedFilesMap[doc.familiar_id] = {};
                    }
                    uploadedFilesMap[doc.familiar_id][doc.tipo_documento] = {
                        url: doc.url_documento,
                        estado: doc.estado
                    };
                });
                setUploadedFiles(uploadedFilesMap);
                const initialDocUploadState = Object.keys(uploadedFilesMap).reduce((acc, familiarId) => {
                    Object.keys(uploadedFilesMap[familiarId]).forEach((docName) => {
                        acc[docName] = true;
                    });
                    return acc;
                }, {});
                setDocUploadState(initialDocUploadState);
            }
        }
    }, [user.id]);

    useEffect(() => {
        if (user && user.id) {
            fetchFamiliares();
            fetchUploadedDocuments();
        }
    }, [user, fetchFamiliares, fetchUploadedDocuments]);

    const allDocumentsApproved = () => {
        let allApproved = true;
        let totalDocuments = 0;
        let approvedCount = 0;
        let rejectedCount = 0;
        let waitingForPinCount = 0;
        let noStateCount = 0;

        for (let familiarId in uploadedFiles) {
            for (let docName in uploadedFiles[familiarId]) {
                const estado = uploadedFiles[familiarId][docName].estado;
                totalDocuments++;
                if (estado === 'aprobado') {
                    approvedCount++;
                } else if (estado === 'rechazado') {
                    rejectedCount++;
                    allApproved = false;
                } else if (estado === 'esperando clave pin') {
                    waitingForPinCount++;
                    allApproved = false;
                } else {
                    noStateCount++;
                    allApproved = false;
                }
            }
        }

        console.log("Resumen de documentos:");
        console.log(`Total documentos: ${totalDocuments}`);
        console.log(`Total documentos aprobados: ${approvedCount}`);
        console.log(`Total documentos rechazados: ${rejectedCount}`);
        console.log(`Total documentos esperando clave pin: ${waitingForPinCount}`);
        console.log(`Total documentos sin estado: ${noStateCount}`);

        return {
            allApproved,
            totalDocuments,
            approvedCount,
            rejectedCount,
            waitingForPinCount,
            noStateCount
        };
    };

    const handleNextStep = async () => {
        if (user && user.id) {
            const { data, error } = await supabase
                .from('customer_journey')
                .upsert({
                    user_id: user.id,
                    estado: 'Cliente Firmar Documentación',
                    timestamp: new Date(),
                    updated_at: new Date()
                }, { onConflict: 'user_id' });

            if (error) {
                console.error('Error updating customer journey:', error);
            } else {
                console.log('Customer journey updated:', data);
                navigate('/firmarautorizacion');
            }
        }
    };

    const renderNextStepButton = () => {
        const { allApproved, totalDocuments, approvedCount } = allDocumentsApproved();

        if (allApproved && approvedCount === totalDocuments) {
            return (
                <button
                    className="bg-blue-600 text-white py-2 px-4 rounded mt-4 hover:bg-blue-700 transition duration-200"
                    onClick={handleNextStep}
                >
                    Ir al siguiente paso
                </button>
            );
        }
        return null;
    };

    const handleObtenerDocumentacion = async () => {
        setLoadingDocumentacion(true);
        try {
            const activeFamiliar = familiares.find(familiar => familiar.id === activeTab);

            if (!activeFamiliar) {
                console.error('Active familiar not found for activeTab:', activeTab);
                setLoadingDocumentacion(false);
                return;
            }

            const relacion = activeFamiliar.relacion.toLowerCase();
            const tipoIngreso = activeFamiliar.tipoIngreso.toLowerCase();

            let documentosNecesarios = [
                ...documentacionConfig.requisitos_generales.doc_hacienda_y_seguridad_social
            ];

            if (relacion === 'titular1') {
                documentosNecesarios = [
                    ...documentosNecesarios,
                    ...documentacionConfig.documentacion_relacion_titular1.doc_hacienda_y_seguridad_social
                ];
            }

            if (tipoIngreso in normalizedDocumentacionConfig.documentacion_ingresos) {
                const docConfig = normalizedDocumentacionConfig.documentacion_ingresos[tipoIngreso];
                documentosNecesarios = [
                    ...documentosNecesarios,
                    ...docConfig.doc_hacienda_y_seguridad_social
                ];
            }

            const documentosMapped = documentosNecesarios
                .map(doc => documentacionConfig.mapeoBankflip[doc])
                .filter(Boolean);

            setUploadedFiles((prevState) => {
                const updatedFiles = { ...prevState };
                documentosNecesarios.forEach((doc) => {
                    if (!updatedFiles[activeTab]) {
                        updatedFiles[activeTab] = {};
                    }
                    updatedFiles[activeTab][doc] = {
                        ...updatedFiles[activeTab][doc],
                        estado: 'esperando clave pin'
                    };
                });
                return updatedFiles;
            });

            const response = await axios.post('https://backend.codigobuenaspracticas.es/api/create-session', {
                documentos: documentosMapped,
                familiarId: activeFamiliar.id,
                clienteId: user.id
            });

            const { session } = response.data;

            if (session && (session.widget_link || session.widgetLink)) {
                const sessionUrl = session.widget_link || session.widgetLink;
                window.open(sessionUrl, '_blank', 'width=600,height=800');
                setSessionInProgress(true); // Cambia el estado a "Obteniendo documentación"
            } else {
                console.error('La respuesta no contiene la propiedad "widget_link".', response.data);
            }
        } catch (error) {
            console.error('Error creating session:', error);
        } finally {
            setLoadingDocumentacion(false);
        }
    };

    const toggleDocUpload = (docName) => {
        setDocUploadState((prevState) => ({
            ...prevState,
            [docName]: !prevState[docName],
        }));
    };

    const handleDrop = useCallback(async (acceptedFiles, docName) => {
        const file = acceptedFiles[0];
        if (!file) return;

        const activeFamiliar = familiares.find(familiar => familiar.id === activeTab);
        if (!activeFamiliar) {
            console.error('Active familiar not found for activeTab:', activeTab);
            return;
        }

        const titular = titulares.find(titular => titular.relacion === 'titular1') || {};

        const sanitizeName = (name) => name ? name.replace(/[^a-z0-9]/gi, '_').toLowerCase() : 'undefined';
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        const fileExtension = file.name.split('.').pop();
        const userName = user.nombre ? sanitizeName(user.nombre) : 'user';
        const titularName = titular.nombre ? sanitizeName(titular.nombre) : 'titular';
        const documentName = docName ? sanitizeName(docName) : 'document';

        const filePath = `${userName}_${titularName}_${documentName}_${timestamp}.${fileExtension}`;

        setDocUploadState((prevState) => ({
            ...prevState,
            [docName]: true,
        }));

        const { error } = await supabase
            .storage
            .from('documentos')
            .upload(filePath, file, {
                cacheControl: '3600',
                upsert: false,
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress((prevState) => ({
                        ...prevState,
                        [docName]: progress,
                    }));
                }
            });

        if (error) {
            console.error('Error uploading file:', error);
            setUploadProgress((prevState) => ({
                ...prevState,
                [docName]: null,
            }));
        } else {
            const { cliente_id } = activeFamiliar;

            const { error: insertError } = await supabase
                .from('documentos')
                .insert([{
                    cliente_id: cliente_id,
                    familiar_id: activeTab,
                    tipo_documento: docName,
                    url_documento: filePath,
                    estado: 'pendiente' // Estado inicial del documento
                }]);

            if (insertError) {
                console.error('Error inserting document data:', insertError);
                setUploadProgress((prevState) => ({
                    ...prevState,
                    [docName]: null,
                }));
            } else {
                setUploadedFiles((prevState) => ({
                    ...prevState,
                    [activeTab]: {
                        ...prevState[activeTab],
                        [docName]: {
                            url: filePath,
                            estado: 'pendiente'
                        },
                    },
                }));
                setUploadProgress((prevState) => ({
                    ...prevState,
                    [docName]: 100,
                }));
            }
        }
    }, [activeTab, familiares, titulares, user.nombre]);


    if (loading) {
        return <p>Loading...</p>;
    }

    const getIcon = (docName) => {
        switch (docName) {
            case 'Renta 2020':
            case 'Renta 2021':
            case 'Renta 2022':
            case 'Renta 2023':
            case 'Ultimas 3 nominas':
            case 'Ultimas 3 facturas':
            case 'Certificado de prestaciones':
            case 'Certificado Sepe':
                return <FaFileInvoice className="text-green-500" />;
            case 'Certificado de empadronamiento':
            case 'Certificado Catastral o Nota de localización':
                return <FaHome className="text-yellow-500" />;
            case 'Copia dni frontal':
            case 'Copia dni anverso':
                return <FaUser className="text-blue-500" />;
            case 'Libro Familia':
            case 'Declaración Responsable':
            case 'Autorización':
            case 'Escritura Hipoteca':
                return <FaFileAlt className="text-red-500" />;
            case 'Escrituras de compraventa de la vivienda':
                return <FaHome className="text-yellow-500" />;
            default:
                return <FaFileAlt className="text-blue-500" />;
        }
    };

    const getStatusClass = (estado) => {
        switch (estado) {
            case 'aprobado':
                return 'bg-green-100 text-green-600';
            case 'rechazado':
                return 'bg-red-100 text-red-600';
            case 'pendiente':
                return 'bg-yellow-100 text-yellow-600';
            case 'esperando clave pin':
                return 'bg-purple-100 text-purple-600';
            default:
                return 'bg-gray-100 text-gray-600';
        }
    };

    const renderDocumentacion = (persona) => {
        const relacion = persona.relacion ? persona.relacion.toLowerCase() : 'otro';
        const tipoIngreso = persona.tipoIngreso ? persona.tipoIngreso.toLowerCase() : 'desconocido';

        let documentacionHaciendaSeguridadSocial = [
            ...normalizedDocumentacionConfig.requisitos_generales.doc_hacienda_y_seguridad_social
        ];

        let documentacionPersonal = [
            ...normalizedDocumentacionConfig.requisitos_generales.documentacion_personal
        ];

        if (relacion === 'titular1') {
            documentacionHaciendaSeguridadSocial = [
                ...documentacionHaciendaSeguridadSocial,
                ...normalizedDocumentacionConfig.documentacion_relacion_titular1.doc_hacienda_y_seguridad_social
            ];

            documentacionPersonal = [
                ...documentacionPersonal,
                ...normalizedDocumentacionConfig.documentacion_relacion_titular1.documentacion_personal
            ];
        }

        if (tipoIngreso in normalizedDocumentacionConfig.documentacion_ingresos) {
            const docConfig = normalizedDocumentacionConfig.documentacion_ingresos[tipoIngreso];
            documentacionHaciendaSeguridadSocial.push(...docConfig.doc_hacienda_y_seguridad_social);
            documentacionPersonal.push(...docConfig.documentacion_personal);
        }

        return (
            <div className="space-y-4">
                <h4 className="font-semibold">Documentación Hacienda y Seguridad Social</h4>
                <h5 className="font-semibold">Te presentamos dos modalidades para subir la documentación, elige la que más te guste:</h5>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div className="bg-white rounded-lg shadow-lg p-4 flex border-4 border-dashed border-purple-600">
                        <div className="mr-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24">
                                <path fill="#FFFF00" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 2L3 14H11L10 22L21 10H13L14 2H13Z" />
                            </svg>
                        </div>
                        <div className="flex-1">
                            <h3 className="text-lg font-semibold mb-2 flex items-center">
                                Clave Pin - en tan solo 5 minutos
                            </h3>
                            <p className="text-gray-600 mb-4">Si tienes Clave Pin puedes descargar tu documentación en tan solo 5 minutos gracias a nuestra tecnología.</p>
                            <button className="bg-purple-600 text-white py-2 px-4 rounded hover:bg-purple-700 transition duration-200" onClick={handleObtenerDocumentacion} disabled={loadingDocumentacion || sessionInProgress}>
                                {loadingDocumentacion ? "Cargando..." : sessionInProgress ? "Obteniendo documentación" : "Obtener documentación"}
                            </button>
                        </div>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-4 flex border-2 border-dashed border-gray-300">
                        <div className="mr-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                        </div>
                        <div className="flex-1">
                            <h3 className="text-lg font-semibold mb-2">Subir documentos (20-40 minutos)</h3>
                            <p className="text-gray-600 mb-4">Si lo prefiere puede hacerlo manualmente, aunque de esta forma le llevará más tiempo. Haz click en cada documento para subir la documentación</p>
                        </div>
                    </div>
                </div>
                <ul className="space-y-2">
                    {documentacionHaciendaSeguridadSocial.map((doc, index) => (
                        <li
                            key={index}
                            className="flex flex-col border rounded-md bg-gray-100 cursor-pointer"
                            onClick={() => toggleDocUpload(doc)}
                        >
                            <div className="flex justify-between items-center p-4">
                                <div className="flex items-center">
                                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-100 mr-2">
                                        {getIcon(doc)}
                                    </div>
                                    <span className="file-name">{doc || 'Documento sin nombre'}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    {uploadedFiles[activeTab] && uploadedFiles[activeTab][doc] && (
                                        <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-semibold ${getStatusClass(uploadedFiles[activeTab][doc].estado)}`}>
                                            <span className="w-2 h-2 mr-2 rounded-full bg-current"></span>
                                            {uploadedFiles[activeTab][doc].estado}
                                        </div>
                                    )}
                                    <button className="text-gray-500">
                                        {docUploadState[doc] ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                                    </button>
                                </div>
                            </div>
                            {docUploadState[doc] && (
                                <div className="p-4 border-t min-w-o">
                                    <StyledDropzone
                                        onDrop={(files) => handleDrop(files, doc)}
                                        progress={uploadProgress[doc]}
                                        isUploaded={uploadedFiles[activeTab] && uploadedFiles[activeTab][doc]}
                                        estado={uploadedFiles[activeTab] && uploadedFiles[activeTab][doc] && uploadedFiles[activeTab][doc].estado}
                                    />
                                    {uploadProgress[doc] !== null && uploadProgress[doc] < 100 && (
                                        <div className="mt-2 w-full break-normal">
                                            <p className="text-purple-600">Cargando...</p>
                                        </div>
                                    )}
                                    {uploadedFiles[activeTab] && uploadedFiles[activeTab][doc] && (
                                        <div className="mt-4 sm:mt-2 text-green-600 text-base sm:text-sm">
                                            <p className='break-all'>Documento subido: {uploadedFiles[activeTab][doc].url}</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
                <h4 className="font-semibold mt-4">Documentación Personal</h4>
                <ul className="space-y-2">
                    {documentacionPersonal.map((doc, index) => (
                        <li
                            key={index}
                            className="flex flex-col border rounded-md bg-gray-100 cursor-pointer"
                            onClick={() => toggleDocUpload(doc)}
                        >
                            <div className="flex justify-between items-center p-4">
                                <div className="flex items-center">
                                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-100 mr-2">
                                        {getIcon(doc)}
                                    </div>
                                    <span className="file-name">{doc || 'Documento sin nombre'}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    {uploadedFiles[activeTab] && uploadedFiles[activeTab][doc] && (
                                        <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-semibold ${getStatusClass(uploadedFiles[activeTab][doc].estado)}`}>
                                            <span className="w-2 h-2 mr-2 rounded-full bg-current"></span>
                                            {uploadedFiles[activeTab][doc].estado}
                                        </div>
                                    )}
                                    <button className="text-gray-500">
                                        {docUploadState[doc] ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                                    </button>
                                </div>
                            </div>
                            {docUploadState[doc] && (
                                <div className="p-4 border-t">
                                    <StyledDropzone
                                        onDrop={(files) => handleDrop(files, doc)}
                                        progress={uploadProgress[doc]}
                                        isUploaded={uploadedFiles[activeTab] && uploadedFiles[doc]}
                                        estado={uploadedFiles[activeTab] && uploadedFiles[activeTab][doc] && uploadedFiles[activeTab][doc].estado}
                                    />
                                    {uploadProgress[doc] !== null && uploadProgress[doc] < 100 && (
                                        <div className="mt-2 w-full">
                                            <p className="text-purple-600">Cargando...</p>
                                        </div>
                                    )}
                                    {uploadedFiles[activeTab] && uploadedFiles[activeTab][doc] && (
                                        <div className="mt-2">
                                            <p className="text-green-600">Documento subido: {uploadedFiles[activeTab][doc].url}</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
                {renderNextStepButton()}
            </div>
        );
    };

    return (
        <div className="p-4 max-w-7xl mx-auto pt-20">
            <h1 className="text-2xl font-bold mb-4">Sube la documentación de las siguientes personas: </h1>
            <div className="flex space-x-4 mb-6 border-b">
                {familiares.map((familiar) => (
                    <div
                        key={familiar.id}
                        className={`py-2 cursor-pointer ${activeTab === familiar.id ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                        onClick={() => setActiveTab(familiar.id)}
                    >
                        {familiar.nombre ? `${familiar.nombre} ${familiar.apellidos}` : 'Desconocido'}
                    </div>
                ))}
            </div>
            <div>
                {familiares.map((familiar) =>
                    activeTab === familiar.id ? (
                        <div key={familiar.id} className="p-4 border rounded-md">
                            <h2 className="text-xl font-semibold mb-4">
                                {familiar.nombre ? `${familiar.nombre} ${familiar.apellidos}` : 'Desconocido'}
                            </h2>
                            {renderDocumentacion(familiar)}
                        </div>
                    ) : null
                )}
            </div>
        </div>
    );
};

export default SubirDocumentacion;
