import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from './services/supabaseClient';
import { UserContext } from './UserContext';
import { FaFileAlt } from 'react-icons/fa';
import SignatureCanvas from 'react-signature-canvas';
import { jsPDF } from 'jspdf';
import Confetti from 'react-confetti';

const FirmarDocumentacion = () => {
    const { user } = useContext(UserContext);
    const [titulares, setTitulares] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDocumento, setSelectedDocumento] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [selectedTitular, setSelectedTitular] = useState(null);
    const [loanNumber, setLoanNumber] = useState('');
    const [bancoHipoteca, setBancoHipoteca] = useState('');
    const [showFullContent, setShowFullContent] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const sigCanvas = useRef({});
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user.id) {
            const fetchTitulares = async () => {
                const { data, error } = await supabase
                    .from('familiares')
                    .select('*')
                    .eq('cliente_id', user.id)
                    .or('relacion.eq.titular1,relacion.eq.titular');

                if (error) {
                    console.error('Error fetching titulares:', error);
                } else {
                    const updatedTitulares = data.map(titular => ({
                        ...titular,
                        documentos: [
                            { nombre: 'Autorización Código Buenas Prácticas', estado: 'pendiente' },
                            { nombre: 'Declaración Responsable', estado: 'pendiente' },
                        ],
                    }));
                    setTitulares(updatedTitulares);
                }
                setLoading(false);
            };

            const fetchBancoHipoteca = async () => {
                const { data, error } = await supabase
                    .from('clientes')
                    .select('bancoHipoteca')
                    .eq('id', user.id);

                if (error) {
                    console.error('Error fetching bancoHipoteca:', error);
                } else if (data.length > 0) {
                    setBancoHipoteca(data[0].bancoHipoteca);
                }
            };

            fetchTitulares();
            fetchBancoHipoteca();
        }
    }, [user]);

    const handleFirmar = (documento, titular) => {
        setSelectedDocumento(documento);
        setSelectedTitular(titular);
        setShowModal(true);
    };

    const getStatusClass = (estado) => {
        switch (estado) {
            case 'pendiente':
                return 'bg-yellow-100 text-yellow-600';
            case 'firmado':
                return 'bg-green-100 text-green-600';
            default:
                return 'bg-gray-100 text-gray-600';
        }
    };

    const truncateText = (text, length) => {
        return text.length > length ? text.substring(0, length) + '...' : text;
    };

    const getDocumentoContenido = (titular, loanNumber, bancoHipoteca, timestamp) => ({
        'Autorización Código Buenas Prácticas': `
            <h2>AUTORIZACIÓN</h2>
            <p>D. ${titular.nombre} ${titular.apellidos} con DNI ${titular.dni} con domicilio en ${titular.domicilio} autoriza al despacho 
            código buenas prácticas (SALATAN ABOGADOS SL) y como letrada y asesora jurídica a Doña Josefa Salazar Salazar Colegiada 4587 ICACordoba con DNI 45558709X de 
            forma expresa a realizar las negociaciones y trasmitir o recabar información a las 
            diferentes entidades bancarias y/ o administraciones públicas en su nombre con el objetivo de 
            alcanzar una reestructuración sobre su préstamo hipotecario.</p>
            <p>Fecha:</p>
            <p>Fdo ${titular.nombre} ${titular.apellidos}</p>
        `,
        'Declaración Responsable': `
            <h2>DECLARACIÓN RESPONSABLE</h2>
            <p>D./Dª.: <b>${titular.nombre} ${titular.apellidos}</b> con NIF: <b>${titular.dni}</b> y
            Domicilio: <b>${titular.domicilio}</b></p>
            <p>En relación con el préstamo hipotecario número: <b>${loanNumber}</b>
            Contratado con la entidad: <b>${bancoHipoteca}</b></p>
            <p>DECLARO RESPONSABLEMENTE:</p>
            <p>Que cumplo con los requisitos exigidos en el Real Decreto-ley 19/2022 de 22 de noviembre por el que se establece un Código de Buenas Prácticas para aliviar la subida de los tipos de interés en préstamos hipotecarios sobre vivienda habitual se modifica el Real Decreto-ley 6/2012 de 9 de marzo de medidas urgentes de protección de deudores hipotecarios sin recursos y se adoptan otras medidas estructurales para la mejora del mercado de préstamos hipotecarios para poder acogerse a dicho Código de Buenas Prácticas para deudores hipotecarios en riesgo de vulnerabilidad aprobado por el Acuerdo del Consejo de Ministros de 22 de noviembre de 2022.</p>
            <p>Que conozco las consecuencias de la aplicación indebida de las medidas para la reestructuración de la deuda hipotecaria inmobiliaria previstas en el artículo 7 del Real Decreto-ley 19/2022 de 22 de noviembre por el que se establece un Código de Buenas Prácticas para aliviar la subida de los tipos de interés en préstamos hipotecarios sobre vivienda habitual se modifica el Real Decreto-ley 6/2012 de 9 de marzo de medidas urgentes de protección de deudores hipotecarios sin recursos y se adoptan otras medidas estructurales para la mejora del mercado de préstamos hipotecarios. Lo anterior implica que si me hubiese beneficiado de las medidas sin reunir los requisitos previstos en el mencionado Código de Buenas Prácticas seré responsable de los daños y perjuicios que se hayan podido producir así como de todos los gastos generados por la aplicación de estas medidas de flexibilización sin perjuicio de las responsabilidades de otro orden a que mi conducta pudiera dar lugar.</p>
            <p>Nombre apellidos y firma: <b>${titular.nombre} ${titular.apellidos}</b></p>
            <p>Fecha: <b>${timestamp}</b></p>
            <p>Modelo Normalizado de Declaración Responsable</p>
        `
    });

    const handleGuardarFirma = async () => {
        if (!selectedTitular) {
            console.error('selectedTitular is not defined');
            return;
        }

        if (loanNumber.trim() === '' && selectedDocumento === 'Declaración Responsable') {
            alert('Por favor, añada el número de su préstamo hipotecario.');
            return;
        }

        const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

        // Actualizar el número de préstamo hipotecario en la base de datos
        const { error: loanNumberUpdateError } = await supabase
            .from('clientes')
            .update({ numero_prestamo_hipotecario: loanNumber })
            .eq('id', user.id);

        if (loanNumberUpdateError) {
            console.error('Error updating loan number:', loanNumberUpdateError);
            return;
        }

        // Generar el PDF con la firma incluida
        const doc = new jsPDF();
        const now = new Date();
        const timestamp = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
        const content = getDocumentoContenido(selectedTitular, loanNumber, bancoHipoteca, timestamp)[selectedDocumento];

        let yPosition = 20;

        if (selectedDocumento === 'Autorización Código Buenas Prácticas') {
            doc.setFontSize(20);
            doc.setFont("arial", "bold");
            const title = "AUTORIZACIÓN";
            const pageWidth = doc.internal.pageSize.getWidth();
            const textWidth = doc.getTextWidth(title);
            const xPositionTitle = (pageWidth - textWidth) / 2;
            doc.text(title, xPositionTitle, yPosition);

            yPosition += 20;

            doc.setFontSize(12);
            doc.setFont("arial", "normal");

            doc.setFont("arial", "bold");
            doc.text(`D. ${selectedTitular.nombre} ${selectedTitular.apellidos} con DNI ${selectedTitular.dni}`, 10, yPosition);
            yPosition += 10;

            doc.setFont("arial", "normal");
            const content1 = `con domicilio en ${selectedTitular.domicilio} autoriza al despacho código buenas prácticas (SALATAN ABOGADOS SL) y como letrada y asesora jurídica a `;
            doc.text(content1, 10, yPosition, { maxWidth: 180 });
            yPosition += 10;

            doc.setFont("arial", "bold");
            doc.text(`Doña Josefa Salazar Salazar Colegiada 4587 ICACordoba con DNI 45558709X`, 10, yPosition);
            yPosition += 10;

            doc.setFont("arial", "normal");
            const content2 = `de forma expresa a realizar las negociaciones y trasmitir o recabar información a las diferentes entidades bancarias y/o administraciones públicas en su nombre con el objetivo de alcanzar una reestructuración sobre su préstamo hipotecario.`;
            doc.text(content2, 10, yPosition, { maxWidth: 180 });

            yPosition += 20;

            doc.setFontSize(14);
            doc.setFont("arial", "bold");
            doc.text("Fecha:", 10, yPosition);
            yPosition += 10;

            doc.setFontSize(12);
            doc.setFont("arial", "normal");
            doc.text(timestamp, 10, yPosition);
            yPosition += 10;

            doc.text(`Fdo: ${selectedTitular.nombre} ${selectedTitular.apellidos}`, 10, yPosition);
            yPosition += 15;

            const imgSignatureWidth = 50;
            const imgSignatureHeight = 20;
            doc.addImage(signatureData, 'PNG', 10, yPosition, imgSignatureWidth, imgSignatureHeight);
        } else if (selectedDocumento === 'Declaración Responsable') {
            doc.setFontSize(20);
            doc.setFont("arial", "bold");
            const title = "DECLARACIÓN RESPONSABLE";
            const pageWidth = doc.internal.pageSize.getWidth();
            const textWidth = doc.getTextWidth(title);
            const xPositionTitle = (pageWidth - textWidth) / 2;
            doc.text(title, xPositionTitle, yPosition);

            yPosition += 20;

            doc.setFontSize(12);
            doc.setFont("arial", "normal");

            const content1 = [
                `D./Dª.: ${selectedTitular.nombre} ${selectedTitular.apellidos} con NIF: ${selectedTitular.dni}`,
                `Domicilio: ${selectedTitular.domicilio}`,
                `En relación con el préstamo hipotecario número: ${loanNumber}`,
                `Contratado con la entidad: ${bancoHipoteca}`,
            ];

            content1.forEach((line, index) => {
                const isBold = index < 4;
                doc.setFont("arial", isBold ? "bold" : "normal");
                doc.text(line, 10, yPosition + (index * 6)); // Espacio reducido a 6
            });

            yPosition += content1.length * 6 + 10; // Espacio reducido a 6

            const content2 = [
                'DECLARO RESPONSABLEMENTE:',
                'Que cumplo con los requisitos exigidos en el Real Decreto-ley 19/2022 de 22 de noviembre por el que se establece un Código de Buenas Prácticas para aliviar la subida de los tipos de interés en préstamos hipotecarios sobre vivienda habitual se modifica el Real Decreto-ley 6/2012 de 9 de marzo de medidas urgentes de protección de deudores hipotecarios sin recursos y se adoptan otras medidas estructurales para la mejora del mercado de préstamos hipotecarios para poder acogerse a dicho Código de Buenas Prácticas para deudores hipotecarios en riesgo de vulnerabilidad aprobado por el Acuerdo del Consejo de Ministros de 22 de noviembre de 2022.',
                'Que conozco las consecuencias de la aplicación indebida de las medidas para la reestructuración de la deuda hipotecaria inmobiliaria previstas en el artículo 7 del Real Decreto-ley 19/2022 de 22 de noviembre por el que se establece un Código de Buenas Prácticas para aliviar la subida de los tipos de interés en préstamos hipotecarios sobre vivienda habitual se modifica el Real Decreto-ley 6/2012 de 9 de marzo de medidas urgentes de protección de deudores hipotecarios sin recursos y se adoptan otras medidas estructurales para la mejora del mercado de préstamos hipotecarios. Lo anterior implica que si me hubiese beneficiado de las medidas sin reunir los requisitos previstos en el mencionado Código de Buenas Prácticas seré responsable de los daños y perjuicios que se hayan podido producir así como de todos los gastos generados por la aplicación de estas medidas de flexibilización sin perjuicio de las responsabilidades de otro orden a que mi conducta pudiera dar lugar.',
            ];

            content2.forEach((paragraph, index) => {
                const splitText = doc.splitTextToSize(paragraph, 180);
                splitText.forEach((line, lineIndex) => {
                    doc.setFont("arial", "normal");
                    doc.text(line, 10, yPosition + (lineIndex * 6)); // Espacio reducido a 6
                });
                yPosition += splitText.length * 6 + 10; // Espacio reducido a 6
            });

            yPosition += 10;

            const content3 = [
                `Nombre apellidos y firma: ${selectedTitular.nombre} ${selectedTitular.apellidos}`,
                `Fecha: ${timestamp}`,
                'Modelo Normalizado de Declaración Responsable',
            ];

            content3.forEach((line, index) => {
                doc.setFont("arial", "bold");
                doc.text(line, 10, yPosition + (index * 6)); // Espacio reducido a 6
            });

            yPosition += content3.length * 6 + 10; // Espacio reducido a 6

            const imgSignatureWidth = 50;
            const imgSignatureHeight = 20;
            doc.addImage(signatureData, 'PNG', 10, yPosition, imgSignatureWidth, imgSignatureHeight);
        }

        const pdfData = doc.output('blob');

        // Sanitize the document name to create a valid file key
        const sanitizedDocumentoNombre = `${selectedTitular.nombre}_${selectedTitular.apellidos}_${selectedDocumento.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.pdf`;
        const fileName = `documentos_firmados/${user.id}/${sanitizedDocumentoNombre}`;

        // Guardar el PDF en Supabase Storage
        const { data, error: uploadError } = await supabase.storage.from('documentos').upload(fileName, pdfData, {
            cacheControl: '3600',
            upsert: true
        });

        if (uploadError) {
            console.error('Error uploading document:', uploadError);
            return;
        }

        // Almacenar la ruta relativa del archivo en la base de datos
        const relativePath = fileName;

        const updatedTitulares = titulares.map(titular => {
            if (titular.id === selectedTitular.id) {
                return {
                    ...titular,
                    documentos: titular.documentos.map(doc =>
                        doc.nombre === selectedDocumento ? { ...doc, estado: 'firmado' } : doc
                    )
                };
            }
            return titular;
        });

        setTitulares(updatedTitulares);

        const tipoDocumento = selectedDocumento === 'Autorización Código Buenas Prácticas' ? 'autorizacion' : 'declaracion_responsable';

        const { error: insertError } = await supabase.from('documentos').insert([
            {
                cliente_id: user.id,
                familiar_id: selectedTitular.id,
                tipo_documento: tipoDocumento,
                url_documento: relativePath,
                estado: 'aprobado',
            },
        ]);

        if (insertError) {
            console.error('Error saving document to Supabase:', insertError);
        }

        // Verificar si todos los documentos están firmados
        const allSigned = updatedTitulares.every(titular =>
            titular.documentos.every(doc => doc.estado === 'firmado')
        );

        if (allSigned) {
            try {
                const { error: journeyError } = await supabase
                    .from('customer_journey')
                    .upsert(
                        { estado: 'Cliente Como Va mi Caso', user_id: user.id, updated_at: new Date() },
                        { onConflict: 'user_id' }
                    );

                if (journeyError) {
                    throw journeyError;
                }

                setShowConfetti(true);
                setTimeout(() => {
                    setShowConfetti(false);
                    navigate('/comovamicasso');
                }, 5000);
            } catch (error) {
                console.error('Error updating customer journey:', error);
                alert('Error al actualizar el estado. Por favor, inténtelo de nuevo.');
            }
        }

        setShowModal(false);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="p-4 max-w-7xl mx-auto pt-20">
            <h1 className="text-2xl font-bold mb-4">Firmar Documentación</h1>
            {titulares.map((titular) => (
                <div key={titular.id} className="mb-8">
                    <h2 className="text-xl font-semibold mb-2">
                        {titular.nombre ? `${titular.nombre} ${titular.apellidos}` : 'Desconocido'}
                    </h2>
                    <ul className="space-y-4">
                        {titular.documentos.map((documento, index) => (
                            <li key={index} className="flex justify-between items-center p-4 border rounded-md bg-gray-100">
                                <div className="flex items-center">
                                    <FaFileAlt className="text-blue-500 mr-2" />
                                    {documento.nombre}
                                </div>
                                <div className="flex items-center space-x-2">
                                    <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-semibold ${getStatusClass(documento.estado)}`}>
                                        {documento.estado === 'pendiente' ? 'Pendiente firma' : 'Firmado'}
                                    </div>
                                    {documento.estado === 'pendiente' && (
                                        <button 
                                            className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-700 transition duration-200"
                                            onClick={() => handleFirmar(documento.nombre, titular)}
                                        >
                                            Firmar
                                        </button>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
            {showModal && selectedTitular && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg max-w-lg w-full">
                        <h2 className="text-2xl font-bold mb-4">Firmar Documento</h2>
                        <div>
                            {selectedDocumento === 'Declaración Responsable' ? (
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: showFullContent ? getDocumentoContenido(selectedTitular, loanNumber, bancoHipoteca, new Date().toLocaleString())[selectedDocumento] : truncateText(getDocumentoContenido(selectedTitular, loanNumber, bancoHipoteca, new Date().toLocaleString())[selectedDocumento], 200) }}></div>
                                    <button
                                        className="text-blue-500 underline"
                                        onClick={() => setShowFullContent(!showFullContent)}
                                    >
                                        {showFullContent ? 'Leer menos' : 'Leer más'}
                                    </button>
                                </div>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: getDocumentoContenido(selectedTitular, loanNumber, bancoHipoteca, new Date().toLocaleString())[selectedDocumento] }}></div>
                            )}
                        </div>
                        {selectedDocumento === 'Declaración Responsable' && (
                            <div className="mb-4">
                                <label htmlFor="loanNumber" className="block text-sm font-medium text-gray-700">
                                    Añade el número de tu préstamo hipotecario
                                </label>
                                <input 
                                    type="text" 
                                    id="loanNumber" 
                                    value={loanNumber} 
                                    onChange={(e) => setLoanNumber(e.target.value)} 
                                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                                />
                            </div>
                        )}
                        <div className="signature-pad bg-gray-100 border rounded-md mb-4 p-4 relative">
                            {!isDrawing && (
                                <div className="absolute inset-0 flex items-center justify-center text-gray-400 pointer-events-none">
                                    Firma aquí
                                </div>
                            )}
                            <SignatureCanvas 
                                penColor="black"
                                canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                ref={sigCanvas}
                                onBegin={() => setIsDrawing(true)}
                            />
                        </div>
                        <div className="flex justify-between">
                            <button 
                                className="mt-4 bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700 transition duration-200"
                                onClick={() => setShowModal(false)}
                            >
                                Cancelar
                            </button>
                            <button 
                                className="mt-4 bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-700 transition duration-200"
                                onClick={() => {
                                    sigCanvas.current.clear();
                                    setIsDrawing(false);
                                }}
                            >
                                Borrar Firma
                            </button>
                            <button 
                                className="mt-4 bg-green-500 text-white py-1 px-3 rounded hover:bg-green-700 transition duration-200"
                                onClick={handleGuardarFirma}
                            >
                                Guardar Firma
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showConfetti && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <Confetti width={window.innerWidth} height={window.innerHeight} />
                    <div className="bg-white p-6 rounded-lg max-w-lg w-full text-center">
                        <h2 className="text-2xl font-bold mb-4">Enhorabuena</h2>
                        <p className="mb-4">Has firmado todos los documentos. Tu expediente será revisado por nuestros abogados y te mantendremos al tanto.</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FirmarDocumentacion;
