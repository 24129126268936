import React from 'react';

function SimuladorCompletado() {
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold mb-4">Simulador Completado</h2>
      <p className="text-lg text-gray-600">Has completado el simulador. Continúa con los siguientes pasos en tu proceso.</p>
    </div>
  );
}

export default SimuladorCompletado;
