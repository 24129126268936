import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { UserProvider, UserContext } from './components/UserContext';
import Signup from './components/Signup';
import SignIn from './components/SignIn';
import ResetPassword from './components/ResetPassword';
import PasswordRecovery from './components/PasswordRecovery';
import SimulacionCBP from './components/SimulacionCBP';
import PrimerosPasos from './components/PrimerosPasos';
import SubirDocumentacion from './components/SubirDocumentacion';
import FirmarAutorizacion from './components/FirmarAutorizacion';
import ComoVaMiCaso from './components/ComoVaMiCaso';
import CasoResuelto from './components/CasoResuelto';
import Sidebar from './components/Sidebar';
import PricingPage from './components/PricingPage';
import PricingCarencia1 from './components/PricingCarencia1';
import PricingCarencia2 from './components/PricingCarencia2';
import PricingCarencia5 from './components/PricingCarencia5';
import SuccessPage from './components/SuccessPage';
import CancelPage from './components/CancelPage';
import SimuladorCompletado from './components/SimuladorCompletado';
import PrimerosPasosCompletado from './components/PrimerosPasosCompletado';
import DocumentacionSubida from './components/DocumentacionSubida';
import supabase from './components/services/supabaseClient';

function ProtectedRoute({ element: Element, ...rest }) {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCustomerState = async () => {
            if (user) {
                try {
                    const { data, error } = await supabase
                        .from('customer_journey')
                        .select('estado')
                        .eq('user_id', user.id)
                        .order('timestamp', { ascending: false })
                        .limit(1)
                        .single();

                    if (error) {
                        console.error(error);
                        navigate('/simulacioncbp'); // Default to the desired route if state fetch fails
                    } else if (data) {
                        console.log('User state data:', data);
                        redirectUser(data.estado);
                    } else {
                        navigate('/simulacioncbp');
                    }
                } catch (error) {
                    console.error('Fetch error: ', error);
                    navigate('/simulacioncbp');
                }
            } else {
                navigate('/signin');
            }
            setLoading(false);
        };

        fetchCustomerState();
    }, [user, navigate]);

    const redirectUser = (estado) => {
        console.log('Navigating to:', estado);
        switch (estado) {
            case 'Cliente Primeros Pasos':
                if (location.pathname !== '/primerospasos') navigate('/primerospasos');
                break;
            case 'Cliente Subir Doc':
                if (location.pathname !== '/subirdocumentacion') navigate('/subirdocumentacion');
                break;
            case 'Cliente Firmar Documentación':
                if (location.pathname !== '/firmarautorizacion') navigate('/firmarautorizacion');
                break;
            case 'Cliente Como Va mi Caso':
                if (location.pathname !== '/comovamicasso') navigate('/comovamicasso');
                break;
            case 'Cliente Caso Resuelto':
                if (location.pathname !== '/casoresuelto') navigate('/casoresuelto');
                break;
            default:
                if (location.pathname !== '/simulacioncbp') navigate('/simulacioncbp');
                break;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return user ? <Element {...rest} /> : <Navigate to="/signin" replace />;
}

function AppContent() {
    const { user } = useContext(UserContext);

    return (
        <div className="flex">
            {user && <Sidebar />} {/* Solo muestra el Sidebar si el usuario está autenticado */}
            <div className="flex-grow">
                <Routes>
                    <Route path='/' element={<Navigate replace to="/signin" />} />
                    <Route path='/signup' element={<Signup />} />
                    <Route path='/signin' element={<SignIn />} />
                    <Route path='/resetpassword' element={<ResetPassword />} />
                    <Route path='/passwordrecovery' element={<PasswordRecovery />} />
                    <Route path='/simulacioncbp' element={<ProtectedRoute element={SimulacionCBP} />} />
                    <Route path='/primerospasos' element={<ProtectedRoute element={PrimerosPasos} />} />
                    <Route path='/subirdocumentacion' element={<ProtectedRoute element={SubirDocumentacion} />} />
                    <Route path='/firmarautorizacion' element={<ProtectedRoute element={FirmarAutorizacion} />} />
                    <Route path='/comovamicasso' element={<ProtectedRoute element={ComoVaMiCaso} />} />
                    <Route path='/casoresuelto' element={<ProtectedRoute element={CasoResuelto} />} />
                    <Route path='/pricing' element={<PricingPage />} />
                    <Route path='/pricing-carencia1' element={<PricingCarencia1 />} />
                    <Route path='/pricing-carencia2' element={<PricingCarencia2 />} />
                    <Route path='/pricing-carencia5' element={<PricingCarencia5 />} />
                    <Route path='/success' element={<SuccessPage />} />
                    <Route path='/cancel' element={<CancelPage />} />
                    <Route path='/simuladorcompletado' element={<SimuladorCompletado />} />
                    <Route path='/primerospasoscompletado' element={<PrimerosPasosCompletado />} />
                    <Route path='/documentacionsubida' element={<DocumentacionSubida />} />
                    <Route path='*' element={<Navigate to="/signin" replace />} />
                </Routes>
            </div>
        </div>
    );
}

function App() {
    return (
        <UserProvider>
            <Router>
                <AppContent />
            </Router>
        </UserProvider>
    );
}

export default App;
