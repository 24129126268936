import React, { useState, useEffect, useContext } from 'react';
import supabase from './services/supabaseClient';
import { UserContext } from './UserContext';
import { useNavigate } from 'react-router-dom';

const PrimerosPasosCompletado = () => {
  const [userData, setUserData] = useState(null);
  const [familyData, setFamilyData] = useState([]);
  const [selectedTab, setSelectedTab] = useState('main');
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.id) {
      fetchUserData(user.id);
      fetchFamilyData(user.id);
    }
  }, [user]);

  const fetchUserData = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('simulacion_resultados')
        .select(`
          nombre,
          apellidos,
          telefono,
          email,
          bancoHipoteca,
          pagoMensualHipoteca,
          cuotaJunio2022,
          cuotaHace4Anos,
          ingresosBrutosAnuales,
          ingresosNetosAnuales,
          ingresosHace4Anos,
          cantidadPorPagar,
          plazoOriginalHipoteca,
          interesAnual
        `)
        .eq('id', userId)
        .maybeSingle();

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        setUserData(data);
      }
    } catch (error) {
      console.error('Unexpected error fetching data:', error);
    }
  };

  const fetchFamilyData = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('familiares')
        .select(`
          nombre,
          apellidos,
          dni,
          tipoIngreso,
          ingresosBrutosAnuales,
          ingresosNetosAnuales,
          relacion
        `)
        .eq('cliente_id', userId);

      if (error) {
        console.error('Error fetching family data:', error);
      } else {
        setFamilyData(data);
      }
    } catch (error) {
      console.error('Unexpected error fetching family data:', error);
    }
  };

  const tabs = [
    { id: 'main', title: 'Información Principal' },
    { id: 'family', title: 'Titulares y Miembros' },
  ];

  const renderTabContent = () => {
    if (selectedTab === 'main') {
      return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          {[
            { label: 'Nombre', value: userData.nombre },
            { label: 'Apellidos', value: userData.apellidos },
            { label: 'Telefono', value: userData.telefono },
            { label: 'Email', value: userData.email },
            { label: 'Banco de Hipoteca', value: userData.bancoHipoteca },
            { label: 'Pago Mensual Hipoteca', value: userData.pagoMensualHipoteca },
            { label: 'Cuota Hipoteca Junio 2022', value: userData.cuotaJunio2022 },
            { label: 'Cuota Hipoteca Hace 4 Años', value: userData.cuotaHace4Anos },
            { label: 'Ingresos Brutos Anuales', value: userData.ingresosBrutosAnuales },
            { label: 'Ingresos Netos Anuales', value: userData.ingresosNetosAnuales },
            { label: 'Ingresos Hace 4 Años', value: userData.ingresosHace4Anos },
            { label: 'Cantidad por Pagar', value: userData.cantidadPorPagar },
            { label: 'Plazo Original Hipoteca', value: userData.plazoOriginalHipoteca },
            { label: 'Interés Anual', value: userData.interesAnual },
          ].map((field, index) => (
            <div key={index}>
              <label className="block text-gray-700 font-semibold">{field.label}:</label>
              <p className="px-3 py-2 bg-gray-100 rounded">{field.value}</p>
            </div>
          ))}
        </div>
      );
    } else if (selectedTab === 'family') {
      return (
        <div>
          <h2 className="text-2xl font-semibold mb-4">Datos de Titulares y Miembros de la Unidad Familiar</h2>
          {familyData.length > 0 ? (
            familyData.map((member, index) => (
              <div key={index} className="border-b-2 border-gray-300 py-4">
                <h3 className="text-lg font-semibold mb-2">{`Titular/Miembro ${index + 1}`}</h3>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  {[
                    { label: 'Nombre', value: member.nombre },
                    { label: 'Apellidos', value: member.apellidos },
                    { label: 'DNI', value: member.dni },
                    { label: 'Tipo Ingreso', value: member.tipoIngreso },
                    { label: 'Ingresos Brutos Anuales', value: member.ingresosBrutosAnuales },
                    { label: 'Ingresos Netos Anuales', value: member.ingresosNetosAnuales },
                    { label: 'Relación', value: member.relacion },
                  ].map((field, index) => (
                    <div key={index}>
                      <label className="block text-gray-700 font-semibold">{field.label}:</label>
                      <p className="px-3 py-2 bg-gray-100 rounded">{field.value}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p>No hay datos disponibles.</p>
          )}
        </div>
      );
    }
  };

  if (!userData) {
    return <div>Cargando datos...</div>;
  }

  return (
    <div className="flex flex-col items-center mt-12 px-4 sm:px-0">
      <div className="w-full max-w-4xl bg-white p-6 shadow-xl rounded-lg">
        <h1 className="text-3xl font-bold mb-6">Gracias por completar los primeros pasos, aquí están los datos completados:</h1>
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-2 sm:space-y-0">
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={`flex-1 text-center py-2 px-4 border-b-2 cursor-pointer ${selectedTab === tab.id ? 'font-bold text-purple-600 border-purple-600' : 'text-gray-400 border-transparent hover:text-purple-600 hover:border-purple-600'}`}
                onClick={() => setSelectedTab(tab.id)}
              >
                {tab.title}
              </div>
            ))}
          </div>
        </div>
        {renderTabContent()}
        <button
          onClick={() => navigate('/subirdocumentacion')}
          className="w-full py-2 mt-4 bg-purple-600 text-white rounded hover:bg-purple-700 transition duration-200"
        >
          Subir Documentación
        </button>
      </div>
    </div>
  );
};

export default PrimerosPasosCompletado;
